<template>
  <span>
    {{ humanizeSize(size) }}
  </span>
</template>
<script setup>
defineProps({
  size: {
    type: Number
  }
});

const humanizeSize = (size) => {
  let i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}
</script>
