<template>
  <div>
    <div
      class="flex items-center cursor-pointer"
      title="Add Payment Method"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Add Payment Method"
      @close="isOpen = false"
    >
      <div class="bg-white flex flex-col w-full p-8 gap-3">
        <Payment
          ref="paymentComponent"
          :client-secret="$page.props.setupIntent"
          @success="(setupIntent) => intentConfirmed(setupIntent)"
          @failure="(error) => handleError(error)"
        />
        <div v-if="errors" class="flex justify-between items-center">
          <div class="text-xs font-medium text-red-700 mr-12">
            {{ errors.message }}
          </div>
        </div>
        <div v-if="$page.props.errors" class="flex justify-between items-center">
          <div v-for="(error, index) in $page.props.errors?.pay" :key="index" class="text-xs font-medium text-red-700 mr-12">
            {{ error }}
          </div>
        </div>
      </div>
      <div
        class="flex items-center rounded-b gap-2 border-t border-gray-200 bg-gray-100 px-8 py-4 justify-end"
      >
        <LoadingButton class="btn-primary cursor-pointer" :loading="form.processing || cardProcessing" @click="save">
          Save
        </LoadingButton>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import { useForm } from '@inertiajs/vue3';
import LoadingButton from '../../Shared/LoadingButton.vue';
import * as $routes from '@/routes';
import Payment from '../../Shared/SetupIntent.vue';

const isOpen = ref(false)
const paymentComponent = ref()
const cardProcessing = ref(false)
const errors = ref(null)

const save = () => {
  cardProcessing.value=true
  paymentComponent.value.createPaymentMethod()
}

const form = useForm({
  pm_id: null
})

const intentConfirmed = (setupIntent) => {
  form.pm_id = setupIntent.payment_method
  form.post($routes.payment_methods(), {
    onSuccess: () => {
      form.reset();
      isOpen.value = false;
      cardProcessing.value = false;
    },
    onError: () => {
      cardProcessing.value = false;
    }
  })
}

const handleError = (error) => {
  cardProcessing.value = false
  errors.value = error
}
</script>
