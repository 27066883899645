<template>
  <form @submit.prevent="$emit('submit')">
    <div class="flex flex-col md:flex-row p-8 gap-4">
      <TextInput
        v-model="form.user.first_name"
        :errors="form.errors.first_name"
        class="w-full "
        label="First name"
      />
      <TextInput
        v-model="form.user.last_name"
        :errors="form.errors.last_name"
        class="w-full"
        label="Last name"
      />
      <TextInput
        v-model="form.user.email"
        :errors="form.errors.email"
        class="w-full"
        label="Email"
      />
    </div>
    <slot />
  </form>
</template>

<script setup>
import TextInput from '@/Shared/TextInput.vue';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['update:modelValue', 'submit'])
const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});
</script>
