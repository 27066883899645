<template>
  <Layout>
    <Head title="Playlists" />
    <h1 class="mb-8 text-3xl font-bold">
      Playlists
    </h1>
    <div class="mb-6 flex items-center justify-between gap-4">
      <SearchFilter
        v-model="search"
        class="w-full max-w-md"
        @reset="reset"
      />
      <CollaborationFilter v-if="filterOptions.length > 1" v-model="filter" :filter-options />
      <template v-if="$page.props.auth.user.storage_sub || parseInt($env.VITE_FREE_PLAYLISTS) === -1 || (parseInt($env.VITE_FREE_PLAYLISTS) > 0 && playlists.data.length < parseInt($env.VITE_FREE_PLAYLISTS))">
        <button class="btn-primary" @click="isOpen = true">
          Create <span class="hidden md:inline">Playlist</span>
        </button>
        <DefaultModal
          :open="isOpen"
          title="Create Playlist"
          @close="isOpen = false"
        >
          <NewPlaylist @success="isOpen = false" />
        </DefaultModal>
      </template>
      <Link v-else :href="$routes.subscriptions()" class="btn-primary">
        Create <span class="hidden md:inline">Playlist</span>
      </Link>
    </div>
    <div class="rounded bg-white shadow">
      <table class="w-full whitespace-nowrap">
        <thead v-if="playlists.data.length">
          <tr class="text-left font-bold hidden lg:table-row">
            <th class="px-6 pb-4 pt-6">
              Title
            </th>
            <th v-if="showOwner" class="px-6 pb-4 pt-6">
              Owner
            </th>
            <th v-if="!!$env.VITE_ENABLE_PLAYLIST_DUE_DATE" class="px-6 pb-4 pt-6">
              Due Date
            </th>
            <th class="px-6 pb-4 pt-6" colspan="2">
              Views
            </th>
          </tr>
        </thead>
        <tbody>
          <Playlist
            v-for="playlist in playlists.data"
            :key="playlist.id"
            :permissions="collaborations.filter((c) => c.user.hashid === playlist.user.hashid )[0]?.permissions.playlists || []"
            :playlist
            :show-owner
          />
          <tr v-if="playlists.data.length === 0">
            <td class="border-t px-6 py-4" colspan="4">
              No playlists found.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination v-if="playlists.meta.last > 1" :meta="playlists.meta" />
  </Layout>
</template>

<script setup>
import { Head, router, Link } from '@inertiajs/vue3';
import { computed, ref, watch } from 'vue';
import { debounce } from "vue-debounce";
import Layout from '@/Layouts/Main.vue';
import Pagination from '@/Shared/Pagination.vue';
import NewPlaylist from '@/Pages/Playlists/New.vue';
import SearchFilter from '@/Shared/SearchFilter.vue';
import CollaborationFilter from '@/Shared/CollaborationFilter.vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import Playlist from './Playlist.vue';

const props = defineProps({
  playlists: {
    type: Object,
    required: true,
  },
  filterOptions: {
    type: Array,
    required: true,
  },
  filters: {
    type: Object,
    required: true,
  },
  collaborations: {
    type: Array,
    required: false
  }
})

const search = ref(props.filters.search ?? '');
const submitDebounce = debounce(() => submitSearch(), 600);
const filter = ref(props.filterOptions.length ? props.filterOptions.find((person) => person.selected === true).value : [])
const showOwner = computed(() => {
  return !!props.filterOptions.length && filter.value === 'all'
})

watch([search], () => {
  submitDebounce();
});

watch([filter], () => {
  submitSearch();
});

const submitSearch = () => router.reload({
  data: {
    q: {
      title_cont: search.value,
    },
    filter: filter.value
  },
  preserveState:false,
  preserveScroll:true
});
const isOpen = ref(false);

const reset = () => search.value = ''

</script>
