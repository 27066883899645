<template>
  <Combobox v-slot="{open}" v-model="selectedPerson">
    <div class="relative z-50">
      <div
        class="relative w-full cursor-default overflow-hidden rounded bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 sm:text-sm"
      >
        <ComboboxInput
          class="w-full border-none py-3 px-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 min-w-28"
          :display-value="(person) => open ? '' : person.text"
          @change="(event) => query = event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 w-full flex items-center pr-2"
        >
          <ChevronUpDownIcon
            class="size-5 text-gray-400 absolute right-2"
            aria-hidden="true"
          />
        </ComboboxButton>
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
        >
          <div
            v-if="filteredPeople.length === 0 && query !== ''"
            class="relative cursor-default select-none px-4 py-2 text-gray-700"
          >
            Nothing found.
          </div>

          <ComboboxOption
            v-for="person in filteredPeople"
            :key="person.id"
            v-slot="{ active }"
            as="template"
            :value="person"
          >
            <li
              class="relative cursor-default select-none py-2 pl-10 pr-4"
              :class="{
                'bg-primary-600 text-white': active,
                'text-gray-900': !active,
              }"
            >
              <span
                class="block truncate"
                :class="{ 'font-medium': isSelected(person), 'font-normal': !isSelected(person) }"
              >
                {{ person.text }}
              </span>
              <span
                v-if="isSelected(person)"
                class="absolute inset-y-0 left-0 flex items-center pl-3"
                :class="{ 'text-white': active, 'text-primary': !active }"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>
<script setup>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { computed, ref, watch } from 'vue';

const props = defineProps({
  filterOptions: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: String
  }
})

let selectedPerson = ref(props.filterOptions.find((person) => person.selected === true))
let query = ref('')
let filteredPeople = computed(() =>
  query.value === ''
    ? props.filterOptions
    : props.filterOptions.filter((person) =>
      person.text
        .toLowerCase().replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    )
)

const isSelected = (person) => person.text === selectedPerson.value.text

const emit = defineEmits(['update:modelValue'])

watch([selectedPerson], () => {
  emit('update:modelValue', selectedPerson.value.value)
});
</script>
