<template>
  <form @submit.prevent="$emit('submit')">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <TextInput
        v-model="form.user.password"
        :errors="form.errors.password"
        class="w-full pb-8 pr-6 lg:w-1/2"
        type="password"
        autocomplete="new-password"
        label="Password"
      />
      <TextInput
        v-model="form.user.password_confirmation"
        :errors="form.errors.password_confirmation"
        class="w-full pb-8 pr-6 lg:w-1/2"
        type="password"
        autocomplete="new-password"
        label="Password Confirmation"
      />
    </div>
    <slot />
  </form>
</template>

<script setup>
import TextInput from '@/Shared/TextInput.vue';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['update:modelValue', 'submit'])
const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});
</script>
