<template>
  <Layout>
    <Head title="New Order" />
    <div class="mb-8 flex max-w-3xl justify-start">
      <h1 class="text-3xl font-bold">
        <a class="text-primary-500 hover:text-primary-800" href="/orders"> Orders </a><span class="font-medium text-primary-400">/</span> New
      </h1>
    </div>

    <OrderForm v-model="form" @submit="submit">
      <LoadingButton :loading="form.processing" class="btn-primary">
        Order Now
      </LoadingButton>
      <div v-for="(error, index) in $page.props.errors" :key="index" class="text-red-700 text-sm px-8 pb-4">
        {{ error }}
      </div>
    </OrderForm>
  </Layout>
</template>

<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import OrderForm from './Form.vue'
import LoadingButton from '../../Shared/LoadingButton.vue';
import * as $routes from '../../routes';
import { v4 as uuid } from 'uuid';

const form = useForm({
  order: {
    projects: [{
        id: uuid(),
        title: '',
        media: 'movie',
        aired: true,
        air_date: '',
        scenes: [{
          id: uuid(),
          description: ''
        }]
    }]
  }
})
const submit = () => {
  form.post($routes.orders())
}
</script>
