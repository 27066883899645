<template>
  <div class="border-b border-gray-300 mb-4">
    <ul class="flex flex-wrap -mb-px text-xs md:text-sm font-medium text-center text-secondary-700">
      <li class="me-2">
        <Link
          :href="$routes.account()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('account') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('account') ? 'page' : null"
        >
          Profile
        </Link>
      </li>
      <li v-if="$env.VITE_ENABLE_CLIENTS" class="me-2">
        <Link
          :href="$routes.representation()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('representation') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('representation') ? 'page' : null"
        >
          Representation
        </Link>
      </li>
      <li v-else class="me-2">
        <Link
          :href="$routes.collaboration_index()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('collaboration') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('collaboration') ? 'page' : null"
        >
          Collaboration
        </Link>
      </li>
      <li class="me-2">
        <Link
          :href="$routes.subscriptions()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('subscriptions') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('subscriptions') ? 'page' : null"
        >
          Membership
        </Link>
      </li>
      <li class="me-2">
        <Link
          :href="$routes.billing()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('billing') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('billing') ? 'page' : null"
        >
          Billing
        </Link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';

const isUrl = (...urls) => {
  let currentUrl = usePage().url.substring(1);

  if (urls[0] === '') {
    return currentUrl === '';
  }

  return urls.filter((url) => currentUrl.startsWith(url)).length;
}
</script>
