<template>
  <div v-if="$page.props.customer?.discount?.coupon" class="text-green-700 text-xs">
    <div>{{ $page.props.customer?.discount?.coupon?.name }}</div>
    {{ $page.props.customer?.discount?.coupon?.percent_off ? `${$page.props.customer?.discount?.coupon?.percent_off}%` : '' }}
    {{ $page.props.customer?.discount?.coupon?.amount_off ? `$${$page.props.customer?.discount?.coupon?.amount_off}%` : '' }}
    off
    <template v-if="$page.props.customer?.discount?.coupon?.duration === 'repeating'">
      <DateString :date="$page.props.customer?.discount?.start * 1000" /> -
      <DateString :date="$page.props.customer?.discount?.end * 1000" />
    </template>
  </div>
  <div v-else class="flex w-full flex-row items-start mt-4 gap-2">
    <TextInput v-model="form.promo_code" class="text-xs" label="Promo Code" :errors="form.errors.promo_code" />
    <div class="whitespace-nowrap rounded bg-primary cursor-pointer hover:bg-primary-600 font-semibold text-white text-xs px-3 py-2.5 mt-6" @click="form.post($routes.subscriptions_promo(), { preserveScroll:true, preserveState:true })">
      Apply
    </div>
  </div>
</template>
<script setup>
import TextInput from '../../Shared/TextInput.vue';
import { useForm } from '@inertiajs/vue3';
import DateString from '../../Shared/DateString.vue';

const form = useForm({ promo_code: ''})
</script>
