// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '~/styles/application.css';
import { createApp, h } from 'vue';
import { router, createInertiaApp } from '@inertiajs/vue3';
import Plausible from 'plausible-tracker';
import * as Sentry from "@sentry/vue";
import VueGtag from 'vue-gtag';

const plausibleUrl = document.querySelector(
  'meta[name="plausible-url"]',
).content;
if (plausibleUrl) {
  const plausible = Plausible({
    domain: document.querySelector('meta[name="app-host"]').content,
    apiHost: plausibleUrl,
  });

  router.on('navigate', () => {
    plausible.trackPageview();
  });
}

import * as Routes from '@/routes';

const pages = import.meta.glob('../Pages/**/*.vue', { eager: true });

createInertiaApp({
  resolve: (name) => {
    const component = pages[`../Pages/${name}.vue`];
    if (!component)
      throw new Error(
        `Unknown page ${name}. Is it located under Pages with a .vue extension?`,
      );

    return component;
  },

  progress: { color: '#FF3269', showSpinner: true },

  title: (title) => (title ? `${title} - ${import.meta.env.VITE_APP_NAME}` : import.meta.env.VITE_APP_NAME),

  setup({ el, App, props, plugin }) {
    const vueApp = createApp({
      render: () => h(App, props),
    });
    vueApp.config.globalProperties.$routes = Routes;
    vueApp.config.globalProperties.$env = import.meta.env;
    Sentry.init({
      app: vueApp,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      // eslint-disable-next-line no-unused-vars
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception && event.event_id) {
          Sentry.showReportDialog({ eventId: event.event_id });
        }
        return event;
      },
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),

      ],
      // Performance Monitoring
      tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", import.meta.env.VITE_APP_URL],
      // Session Replay
      replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE,
      replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
    });
    vueApp.use(VueGtag, {
      includes: [
        { id: import.meta.env.VITE_GOOGLE_ADWORDS_PROPERTY_ID }
      ],
      config: {
        id: import.meta.env.VITE_GOOGLE_ANALYTICS_PROPERTY_ID
      }
    });
    vueApp.use(plugin).mount(el);
  },
});
