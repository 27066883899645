<template>
  <Layout>
    <Head title="First Playlist" />
    <h2 class="mb-4 text-4xl tracking-tight font-extrabold">
      Welcome to {{ $env.VITE_APP_NAME }}!
    </h2>
    <p class="mb-8 font-light text-gray-500 sm:text-xl">
      Let's get started creating your first tribute
    </p>
    <PlaylistForm v-model="form" class="bg-white rounded max-w-prose shadow" @submit="submit">
      <div
        class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
      >
        <LoadingButton
          :loading="form.processing"
          class="btn-primary"
          type="submit"
        >
          Next&nbsp;<ArrowRightIcon class="size-4 inline" />&nbsp;Add Contributors
        </LoadingButton>
      </div>
    </PlaylistForm>
  </Layout>
</template>
<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import PlaylistForm from '../Playlists/Form.vue';
import LoadingButton from '../../Shared/LoadingButton.vue';
import * as $routes from '../../routes';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';

const form = useForm({
  playlist: {}
})

const submit = () => {
  form.post($routes.playlists());
}
</script>
