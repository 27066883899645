<template>
  <li v-if="view === 'grid'" class="bg-white shadow rounded-b">
    <div class="relative group bg-black" :class="{'cursor-pointer': !upload.expired}">
      <div v-if="upload.expired" class="w-full absolute flex justify-end">
        <p class="text-white bg-black px-3 opacity-100 z-10 leading-8">
          Expired
        </p>
      </div>
      <div v-else-if="$page.props.auth.user.hashid === upload.user.hashid && !$page.props.auth.user.sponsored && !['active', 'past_due', 'incomplete', 'trialing'].includes($page.props.auth.user.storage_sub?.status)" class="w-full absolute flex justify-end">
        <p class="text-white bg-black px-3 opacity-100 z-10 leading-8">
          Expires in {{ upload.expires_in_days }} days
        </p>
      </div>
      <PlayMedia v-if="!upload.expired" :upload>
        <div class="h-full w-full absolute opacity-80 flex justify-center">
          <PlayIcon class="text-white w-20 group-hover:text-gray-300" />
        </div>
      </PlayMedia>
      <img v-if="upload.preview" :src="upload.preview" class="object-cover m-auto max-h-full h-60" :class="{ 'opacity-60' : !!upload.expired }" :alt="upload.name">
      <div v-else class="bg-black w-full h-60">
        <div class="text-green-300 text-xs p-2 flex gap-1 items-center">
          Thumbnail generating
          <div class="btn-spinner border-t-green-300 border-b-green-300 border-r-green-300 text-[6px]" />
        </div>
      </div>
      <div v-if="!upload.processed" class="text-green-300 text-xs p-2 flex gap-1 absolute bottom-0 bg-black/50 items-center w-full">
        Transcoding for maximum compatibility
        <div class="btn-spinner border-t-green-300 border-b-green-300 border-r-green-300 text-[6px]" />
      </div>
    </div>
    <div class="flex flex-col p-4">
      <div class="flex items-center justify-between">
        <div class="flex items-center w-10/12 justify-left">
          <p class="w-full overflow-hidden text-ellipsis h-6 text-nowrap max-w-full leading-6">
            {{ upload.name }}
          </p>
        </div>
        <div v-if="isOwner" class="px-2 hidden lg:block text-secondary hover:text-secondary-700 cursor-pointer">
          <HeartIconSolid v-if="upload.favorite" class="size-6" @click="unFavorite(upload)" />
          <HeartIcon v-else class="size-6" @click="favorite(upload)" />
        </div>
        <Popover class="relative lg:hidden">
          <PopoverButton class="text-primary hover:text-primary-700">
            <EllipsisVerticalIcon class="size-6" />
          </PopoverButton>

          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
          >
            <PopoverPanel
              class="absolute right-0 z-50 bg-white rounded shadow p-2 flex flex-col gap-2"
            >
              <AddToPlaylist :upload class="m-auto" />
              <div class="flex">
                <div v-if="canManage" class="px-2">
                  <HeartIconSolid v-if="upload.favorite" class="size-6 text-secondary cursor-pointer" @click="router.put($routes.upload(upload.hashid), {upload: {favorite: false}})" />
                  <HeartIcon v-else class="size-6 cursor-pointer" @click="router.put($routes.upload(upload.hashid), {upload: {favorite: true}})" />
                </div>
                <EditModal v-if="canManage" :upload>
                  <PencilIcon class="size-6" />
                </EditModal>
                <a :href="upload.url_downloadable" class="px-2" download>
                  <CloudArrowDownIcon class="size-6" />
                </a>
                <Destroy v-if="canManage" :upload>
                  <TrashIcon class="size-6 text-red-700" />
                </Destroy>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
      <div class="hidden lg:flex flex-row items-center justify-between mt-4">
        <AddToPlaylist v-if="!upload.expired" :upload />
        <Link v-else :href="$routes.subscriptions()" class="btn-primary">
          Restore
        </Link>
        <div class="flex mt-6 justify-around w-full sm:mt-0 sm:justify-end">
          <EditModal v-if="canManage" :upload>
            <PencilIcon class="size-6" />
          </EditModal>
          <a v-if="!upload.expired" :href="upload.url_downloadable" class="px-2 text-primary hover:text-primary-700" download>
            <CloudArrowDownIcon class="size-6" />
          </a>
          <Destroy v-if="canManage" :upload>
            <TrashIcon class="size-6 text-red-700 hover:text-red-600" />
          </Destroy>
        </div>
      </div>
    </div>
  </li>
  <tr
    v-if="view === 'list'"
    class="focus-within:bg-gray-100 hover:bg-gray-100"
  >
    <td class="border-t">
      <div class="flex pl-4 py-2">
        <div class="relative group bg-black cursor-pointer">
          <PlayMedia :upload>
            <div class="h-full w-full absolute opacity-80 flex justify-center">
              <PlayIcon class="text-white w-6 group-hover:text-gray-300" />
            </div>
          </PlayMedia>
          <img v-if="upload.preview" :alt="upload.name" :src="upload.preview" class="object-cover size-12">
          <div v-else class="bg-black size-12 p-1">
            <div class="btn-spinner border-t-green-300 border-b-green-300 border-r-green-300 text-[6px]" />
          </div>
        </div>
        <span
          class="px-6 py-4 max-w-56 sm:max-w-prose overflow-hidden text-ellipsis flex items-center"
        >
          {{ upload.name }}
          <span v-if="!upload.processed" class="text-green-700 text-xs flex gap-1 items-center ml-4">
            Transcoding for maximum compatibility
            <span class="btn-spinner border-t-green-700 border-b-green-700 border-r-green-700 text-[6px]" />
          </span>
        </span>
        <span v-if="upload.expired" class="ml-auto px-6 py-4  overflow-hidden text-ellipsis flex items-center">
          <p>
            Expired
          </p>
        </span>
        <span v-else-if="$page.props.auth.user.hashid === upload.user.hashid && !$page.props.auth.user.sponsored && !['active', 'past_due', 'incomplete', 'trialing'].includes($page.props.auth.user.storage_sub?.status)" class="ml-auto px-6 py-4  overflow-hidden text-ellipsis flex items-center">
          <p>
            Expires in {{ upload.expires_in_days }} days
          </p>
        </span>
      </div>
    </td>
    <td class="border-t hidden md:table-cell">
      <span
        v-if="upload.user.hashid === $page.props.auth.user.hashid"
        class="px-6 py-4 text-xs"
      >
        {{ upload.author.hashid !== $page.props.auth.user.hashid ? `by ${upload.author.name}` : '' }}
      </span>
    </td>
    <td class="border-t hidden lg:table-cell">
      <AddToPlaylist v-if="!upload.expired" :upload />
      <Link v-else-if="$page.props.auth.user.hashid === upload.user.hashid" :href="$routes.subscriptions()" class="btn-primary">
        Restore
      </Link>
    </td>
    <td class="w-px border-t">
      <div class="px-2 hidden lg:flex">
        <div v-if="isOwner" class="px-2 text-secondary hover:text-secondary-700">
          <HeartIconSolid v-if="upload.favorite" class="size-6" @click="unFavorite(upload)" />
          <HeartIcon v-else class="size-6" @click="favorite(upload)" />
        </div>
        <EditModal v-if="canManage" :upload>
          <PencilIcon class="size-6" />
        </EditModal>
        <a v-if="!upload.expired" :href="upload.url_downloadable" class="px-2 text-primary hover:text-primary-700">
          <CloudArrowDownIcon class="size-6" download />
        </a>
        <Destroy v-if="canManage" :upload>
          <TrashIcon class="size-6 text-red-700 hover:text-red-600" />
        </Destroy>
      </div>
      <div class="px-2 flex lg:hidden">
        <Popover class="relative">
          <PopoverButton>
            <EllipsisVerticalIcon class="size-6" />
          </PopoverButton>

          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
          >
            <PopoverPanel
              class="absolute right-0 z-50 bg-white rounded shadow p-2 flex flex-col gap-2"
            >
              <AddToPlaylist v-if="!upload.expired" :upload class="m-auto" />
              <Link v-else-if="$page.props.auth.user.hashid === upload.user.hashid" :href="$routes.subscriptions()" class="btn-primary m-auto">
                Restore
              </Link>
              <div class="flex">
                <div v-if="canManage" class="px-2">
                  <HeartIconSolid v-if="upload.favorite" class="size-6 text-secondary cursor-pointer" @click="router.put($routes.upload(upload.hashid), {upload: {favorite: false}})" />
                  <HeartIcon v-else class="size-6 cursor-pointer" @click="router.put($routes.upload(upload.hashid), {upload: {favorite: true}})" />
                </div>
                <EditModal v-if="canManage" :upload>
                  <PencilIcon class="size-6" />
                </EditModal>
                <a v-if="!upload.expired" :href="upload.url_downloadable" class="px-2">
                  <CloudArrowDownIcon class="size-6" download />
                </a>
                <Destroy v-if="canManage" :upload>
                  <TrashIcon class="size-6 text-red-700" />
                </Destroy>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
    </td>
  </tr>
  <div v-if="view === 'quick-upload'">
    <div class="relative group bg-black cursor-pointer">
      <PlayMedia :upload>
        <div class="h-full w-full absolute opacity-80 flex justify-center">
          <PlayIcon class="text-white w-20 group-hover:text-gray-300" />
        </div>
      </PlayMedia>
      <img :src="upload.preview" class="object-cover w-full max-h-full h-60" :alt="upload.name">
    </div>
  </div>
</template>
<script setup>
import { TrashIcon, CloudArrowDownIcon, PencilIcon, HeartIcon, EllipsisVerticalIcon } from '@heroicons/vue/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/vue/24/solid';
import { PlayIcon } from '@heroicons/vue/24/solid';
import { router, usePage, Link } from '@inertiajs/vue3';
import EditModal from './Edit.vue'
import AddToPlaylist from '../PlaylistUploads/New.vue';
import * as $routes from '@/routes';
import Destroy from './Destroy.vue';
import PlayMedia from './Play.vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

const props = defineProps({
  upload: {
    type: Object,
    required: true,
  },
  view: {
    type: String,
    required: false,
    default: 'grid'
  }
})

const isOwner = props.upload.user?.hashid === usePage().props.auth?.user?.hashid
const canManage = isOwner || props.upload.author.hashid === usePage().props.auth?.user?.hashid

const favorite = (upload) => {
  router.put($routes.upload(upload.hashid), {upload: {favorite: true}}, {preserveScroll:true})
}

const unFavorite = (upload) => {
  router.put($routes.upload(upload.hashid), {upload: {favorite: false}}, {preserveScroll:true})
}
</script>
