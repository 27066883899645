<template>
  <Layout>
    <Head :title="`${playlist.title} | ${user.first_name} ${user.last_name} | Quick Upload`" />
    <FlashMessages />

    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <div class="w-full max-w-md">
        <Logo class="mx-auto block w-full max-w-xs h-24" />
        <div class="mt-8 rounded-t bg-white shadow-xl px-8 pt-6 gap-2 flex flex-col">
          <ol class="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center sm:text-base sm:p-4 sm:space-x-4 bg-white justify-center">
            <li class="flex items-center" :class="!started ? 'text-primary-600' : ''">
              Welcome
              <svg class="w-3 h-3 ms-2 sm:ms-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
              </svg>
            </li>
            <li class="flex items-center" :class="(started) ? 'text-primary-600' : ''">
              Media
              <svg class="w-3 h-3 ms-2 sm:ms-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
              </svg>
            </li>
            <li class="flex items-center">
              Finalize
            </li>
          </ol>
        </div>

        <template v-if="!started">
          <div class="bg-white shadow-xl px-8 pt-4 gap-2 flex flex-col">
            <p class="pb-4 font-bold text-xl text-center">
              {{ playlist.title }}
            </p>
            <p>To start, please let {{ user.first_name }} {{ user.last_name }} know who you are</p>
            <form @submit.prevent="$emit('submit')">
              <div class="gap-6 flex flex-wrap w-full py-4">
                <TextInput
                  v-model="form.guest.first_name"
                  :errors="form.errors.first_name"
                  label="First Name"
                  class="w-full"
                />

                <TextInput
                  v-model="form.guest.last_name"
                  :errors="form.errors.last_name"
                  label="Last Name"
                  class="w-full"
                />

                <TextInput
                  v-model="form.guest.email"
                  type="email"
                  :errors="form.errors.email"
                  label="Email"
                  class="w-full"
                />
              </div>
            </form>
            <p class="text-xs pb-4">
              By submitting media, you signify your agreement to our
              <a class="text-primary hover:text-primary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/terms-and-conditions`" target="_blank">terms of use</a> and
              <a class="text-primary hover:text-primary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/privacy-policy`" target="_blank">privacy policy</a>
            </p>
          </div>
          <div>
            <div
              class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
            >
              <button class="btn-primary ml-auto" @click="start">
                Get Started
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="bg-white rounded-b">
            <div class="gap-6 flex w-full p-8 flex-col">
              <p class="pb-4 font-bold text-xl text-center">
                {{ playlist.title }}
              </p>
              <p class="text-sm text-green-700 text-center">{{displayMessage}}</p>
              <DropZone
                ref="dropZone"
                :form-data="formData"
                :multiple="false"
                :route="$routes.public_upload()"
                @processing="dzProcessing = true"
                @success="dzProcessing = false"
                @ready="(count) => readyCount = count"
              />
            </div>
            <div
              v-show="readyCount > 0"
              class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
            >
              <LoadingButton
                :loading="dzProcessing"
                class="btn-primary ml-auto"
                @click="submit"
              >
                Upload Media
              </LoadingButton>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, router, useForm, usePage } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue';
import FlashMessages from '@/Shared/FlashMessages.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import Logo from '@/Shared/Logo.vue';
import * as $routes from '@/routes';
import { onUnmounted, ref } from 'vue';
import DropZone from '../../Shared/DropZone.vue';
import TextInput from '../../Shared/TextInput.vue';
import { useCookies } from '@vueuse/integrations/useCookies'

const cookies = useCookies(['locale'])
const props = defineProps(['invitation', 'user', 'playlist', 'media'])
const dropZone = ref()
const dzProcessing = ref(false)
const started = ref(false)
const editing = ref(false)
const displayMessage = ref('')
const readyCount = ref(0)

const formData = {
  upload: { },
  playlist_id: props.playlist.id,
  signed_blob_id: null,
  first_name: cookies.get('_guest_id')?.first_name,
  last_name: cookies.get('_guest_id')?.last_name,
  email: cookies.get('_guest_id')?.email,
}

if (usePage().props.flash.success) {
  started.value = true
  displayMessage.value = "Thanks for your contribution!  Please feel free to add another."
}

const submit = () => {
  dropZone.value.submit();
}

const finalize = () => {
  if (nameForm.isDirty) {
    nameForm.put($routes.quick_rename(props.invitation.code), { preserveState: true, preserveScroll: true, onSuccess: () => router.put($routes.finalize_contribution(props.invitation.code)) })
  } else {
    router.put($routes.finalize_contribution(props.invitation.code))
  }
}

let timer = setInterval(() => {
  if (false) {
    router.reload({
      preserveState: true , preserveScroll: true
    })
  }
}, 1000);

const nameForm = useForm({
  upload: {
    name: ''
  }
})

const start = () => {
  cookies.set('_guest_id', JSON.stringify(
    {
      first_name: form.guest.first_name,
      last_name: form.guest.last_name,
      email: form.guest.email
    }
  ))
  started.value=true
}

onUnmounted(() => {
  clearInterval(timer);
});

const updateName = () => {
  editing.value = false
  nameForm.put($routes.quick_rename(props.invitation.code), { preserveState: true, preserveScroll: true })
}

const requestInviteForm = useForm({
  invitation: {
    first_name: '',
    last_name: '',
    email: '',
    invitable_id: props.playlist.id,
    invitable_type: 'Playlist'
  }
})

const submitRequest = () =>  requestInviteForm.post($routes.request_invite(props.invitation.code))

const form = useForm({
  guest: {
    first_name: cookies.get('_guest_id')?.first_name,
    last_name: cookies.get('_guest_id')?.last_name,
    email: cookies.get('_guest_id')?.email,
  }
})
</script>
