<template>
  <Layout>
    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <Head title="Sign Up" />
      <div class="w-full max-w-md">
        <Logo class="mx-auto block w-full max-w-xs h-24" />
        <form
          class="mt-8 overflow-hidden rounded-lg bg-white shadow-xl"
          @submit.prevent="form.post($routes.users())"
        >
          <div class="px-10 py-12">
            <FlashMessages />
            <h1 class="text-center text-3xl font-bold">
              Sign Up
            </h1>
            <div class="mx-auto mt-6 w-24 border-b-2" />
            <TextInput
              v-model="form.user.email"
              :disabled="!!invitation"
              :errors="form.errors.email"
              class="mt-6"
              label="Email"
              type="email"
              autofocus
              autocapitalize="off"
              autocomplete="email"
            />
            <TextInput
              v-model="form.user.first_name"
              :errors="form.errors.first_name"
              class="mt-6"
              label="First Name"
              autofocus
              autocapitalize="off"
            />
            <TextInput
              v-model="form.user.last_name"
              :errors="form.errors.last_name"
              class="mt-6"
              label="Last Name"
              autofocus
              autocapitalize="off"
            />
            <TextInput
              v-if="resource.business"
              v-model="form.user.company_name"
              :errors="form.errors.company_name"
              class="mt-6"
              label="Company Name"
              autofocus
              autocapitalize="off"
            />
            <TextInput
              v-model="form.user.password"
              :errors="form.errors.password"
              class="mt-6"
              label="Password"
              type="password"
            />
            <TextInput
              v-model="form.user.password_confirmation"
              :errors="form.errors.password_confirmation"
              class="mt-6"
              label="Password Confirmation"
              type="password"
            />
            <label class="form-label mt-6 text-sm">
              <Checkbox v-model="form.user.tos_acceptance" class="mr-2" :checked="!!form.user.tos_acceptance" required />
              By signing up, you agree to our
              <a class="text-primary hover:text-primary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/terms-and-conditions`" target="_blank">terms of use</a> and
              <a class="text-primary hover:text-primary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/privacy-policy`" target="_blank">privacy policy</a>
            </label>
          </div>

          <div class="flex border-t border-gray-200 bg-gray-100 px-10 py-4">
            <LoadingButton
              :loading="form.processing"
              class="btn-primary ml-auto"
              type="submit"
              @click="submit"
            >
              Sign Up
            </LoadingButton>
          </div>
        </form>
        <Link :href="$routes.new_user_session()" class="mt-4 block text-center text-white">
          Login
        </Link>
        <Link :href="$routes.new_user_password()" class="mt-4 block text-center text-white">
          Forgot password?
        </Link>
        <Link :href="$routes.new_user_confirmation()" class="mt-4 block text-center text-white">
          Resend confirmation email
        </Link>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue';
import FlashMessages from '@/Shared/FlashMessages.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import Logo from '@/Shared/Logo.vue';
import TextInput from '@/Shared/TextInput.vue';
import * as $routes from '@/routes';
import Checkbox from '../../Shared/Checkbox.vue';

const props = defineProps(['resource', 'invitation'])

const form = useForm({
  user: {
    ...props.resource,
    password: '',
  },
  invitation: props.invitation?.code
})

const submit = () => {
  if (form.user.tos_acceptance) {
    form.post($routes.users())
  }
}
</script>
