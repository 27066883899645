<template>
<pre>{{form}}</pre>
  <button class="btn-primary" @click="submit()">Resend</button>
</template>
<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import * as $routes from '../../routes';

const form = useForm({
  user: {
    email: usePage().props.auth.user.email,
  },
})

const submit = () => {
  form.post($routes.user_confirmation())
}
</script>
