<template>
  <ul class="playlist rounded-b bg-white shadow" :style="{ '--count': playlistData.length }">
    <li
      v-for="(media, i) in playlistData"
      :key="i"
      class="focus-within:bg-gray-100 hover:bg-gray-100 flex flex-row justify-between border-t "

      :title="media.description"
      :class="{ 'text-primary-700': index === i }"
      @click="emit('update:index', i)"
    >
      <div class="flex items-center px-6 py-4">
        {{ media.name }}
      </div>
      <div class="flex flex-row items-center px-6 py-4">
        <div><DurationString :duration="media.duration" /></div>
        <a v-if="$env.VITE_APP_ID !== 'hff'" :href="media.url_downloadable" class="px-2 text-quaternary-600 hover:text-quaternary-800" download>
          <CloudArrowDownIcon class="size-8  ml-2" />
        </a>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { CloudArrowDownIcon } from '@heroicons/vue/24/outline'
import DurationString from './DurationString.vue';

defineProps({
  index: {
    type: Number,
    required: true
  },
  playlistData: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['update:index']);
</script>
