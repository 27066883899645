<template>
  <Listbox v-model="selectedPlan">
    <div class="relative">
      <ListboxButton
        class="form-input"
      >
        <span class="block truncate">{{ selectedPlan.name }} - {{ selectedPlan.frequency }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronUpDownIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base divide-gray-300 divide-y shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="option in planOptions"
            :key="option.value"
            :value="option"
            as="template"
          >
            <li
              class="relative cursor-default select-none py-2 px-4 hover:bg-primary-600 hover:text-white text-left"
            >
              <span
                class="font-normal block truncate"
              >{{ option.name }} - {{ option.frequency }}</span>
              <div class="flex flex-row justify-between">
                <span
                  class="font-bold text-xs block truncate"
                >
                  <span :class="{'line-through': !!$page.props.customer?.discount?.coupon}">${{ option.price }}</span>
                  <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                    <DollarAmount
                      :amount="Math.floor(option.price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100"
                    />
                  </span>
                  /{{ option.frequency === 'monthly' ? 'month' : 'year' }}</span>
                <span
                  class="font-bold text-xs block truncate"
                >{{ option.storage_minutes }} minutes</span>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
<script setup>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { ref, watch } from 'vue';
import DollarAmount from './DollarAmount.vue';

const props = defineProps({
  planOptions: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: String
  }
})

const selectedPlan = ref(props.planOptions[0])

const emit = defineEmits(['update:modelValue'])

watch([selectedPlan], () => {
  emit('update:modelValue', selectedPlan.value.value)
});
</script>
