Edit.vue<template>
  <Layout>
    <Head :title="`${playlist.title}`" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-primary-500 hover:text-primary-800"
        :href="$routes.playlists()"
      >
        Playlists
      </Link>
      <span class="font-medium text-primary-400">/</span>
      {{ playlist.title }}
    </h1>

    <PlaylistMenu :playlist />
    <div class="flex flex-wrap w-full items-start justify-between gap-6">
      <div v-if="canManage" class="max-w-3xl rounded bg-white shadow sm:min-w-96 w-full sm:w-auto">
        <PlaylistForm
          v-model="form"
          @submit="form.put($routes.playlist(playlist.hashid))"
        >
          <div
            class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
          >
            <Destroy
              v-if="!playlist.deleted_at"
              :playlist
            >
              <span class="text-red-700 hover:underline">
                Delete <span class="hidden md:inline">Playlist</span>
              </span>
            </Destroy>
            <LoadingButton
              :loading="form.processing"
              class="btn-primary ml-auto"
              type="submit"
            >
              Update Playlist
            </LoadingButton>
          </div>
        </PlaylistForm>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue'
import * as $routes from '@/routes';
import PlaylistForm from '../Playlists/Form.vue';
import omit from 'lodash/omit';
import LoadingButton from '../../Shared/LoadingButton.vue';
import Destroy from './Destroy.vue';
import PlaylistMenu from './Components/PlaylistMenu.vue';

const props = defineProps({
  playlist: {
    type: Object,
    required: true,
  }
})

const form = useForm({
  playlist: {
    ...omit(props.playlist, 'id', 'deleted_at'),
    timezone: props.playlist.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
})

const canManage = usePage().props.auth.user.hashid === props.playlist.user.hashid || props.playlist.permissions?.manage

</script>
