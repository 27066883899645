<template>
  <div v-for="(project, index) in projects" :key="index" class="rounded bg-white shadow border border-black p-6 w-full flex flex-col gap-2 relative">
    <div class="cursor-pointer text-red-700 hover:text-red-600 absolute top-2 right-2" @click="removeProject(project.id)">
      <TrashIcon class="size-5" />
    </div>
    <TextInput
      v-model="project.title"
      label="Project Title"
    />
    <div class="flex gap-4">
      <InputRadio v-model="project.media" :name="`media_type_${index}`" value="movie">
        Movie
      </InputRadio>
      <InputRadio v-model="project.media" :name="`media_type_${index}`" value="television">
        Television
      </InputRadio>
      <InputRadio v-model="project.media" :name="`media_type_${index}`" value="web_content">
        Web content
      </InputRadio>
    </div>

    <div v-if="project.media === 'television'">
      <TextInput
        v-model="project.season"
        label="Season"
      />
      <TextInput
        v-model="project.episode"
        label="Episode"
      />
    </div>
    <TextInput
      v-if="project.media === 'web_content'"
      v-model="project.url"
      label="URL"
    />
    <label class="cursor-pointer">
      Has media aired yet?
      <Checkbox
        v-model="project.aired"
        :checked="!!project.aired"
        class="cursor-pointer"
      />
    </label>
    <template v-if="!project.aired">
      <label class="form-label">Air Date</label>
      <VueTailwindDatepicker
        :id="`air_date_${uuid()}`"
        v-model="project.air_date"
        as-single
        :name="`air_date_${uuid()}`"
        :formatter="formatter"
        input-classes="form-input w-full"
      />
    </template>

    <div v-for="(scene, i) in project.scenes" :key="i" class="rounded bg-secondary-50 shadow p-6 relative">
      <TextareaInput
        v-model="scene.description"
        label="Scene Description"
        placeholder="Please give a brief description of the scene including clothing worn, location, actors/characters present and/or time codes."
      />
      <div class="cursor-pointer text-red-700 hover:text-red-600 absolute top-2 right-2" @click="removeScene(scene.id)">
        <TrashIcon class="size-5" />
      </div>
    </div>
    <div>
      <div class="btn-secondary inline-block cursor-pointer" @click="project.scenes.push({ id: uuid(), description:'' })">
        Add Scene
      </div>
    </div>
  </div>
</template>
<script setup>
import Checkbox from '../../../Shared/Checkbox.vue';
import InputRadio from '../../../Shared/InputRadio.vue';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import TextareaInput from '../../../Shared/TextareaInput.vue';
import TextInput from '../../../Shared/TextInput.vue';
import {TrashIcon} from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import { v4 as uuid } from 'uuid';

const formatter = { date: 'YYYY/M/D', month: 'MMM' }

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  }
})

const emit = defineEmits(['update:modelValue']);

const projects = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});

const removeProject = (project_id) => {
  if (confirm('Are you sure you want to delete this project?')) {
    projects.value = projects.value.filter((p) => p.id !== project_id)
  }
}
const removeScene = (scene_id) => {
  if (confirm('Are you sure you want to delete this scene?')) {
    projects.value = projects.value.map((p) => {
      return { ...p, scenes: p.scenes.filter((s) => s.id !== scene_id) }
    })
  }
}
</script>
