<template>
  <div>
    <div
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      :title="`Play Media: ${upload.name}`"
      max-width="max-w-screen-lg"
      @close="isOpen = false"
    >
      <div class="bg-white flex p-8 rounded-b">
        <VideoPlayer
          :class="['w-full','h-[75vh]','video-player', 'vjs-big-play-centered', { loading: !state }]"
          :sources="mediaConfig.sources"
          :poster="mediaConfig.poster"
          :tracks="mediaConfig.tracks"
          :autoplay="config.autoplay"
          :loop="config.loop"
          crossorigin="anonymous"
          playsinline
          :volume="config.volume"
          :controls="true"
          :muted="false"
          @mounted="handleMounted"
        />
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import { VideoPlayer } from '@videojs-player/vue';

const props = defineProps(['upload'])
const isOpen = ref(false)

const player = ref()
const state = ref()

const playlistData = {
  poster: props.upload.preview,
  sources: [
    { src: props.upload.url_playable, type: props.upload.content_type },
  ]
}

const config = ref({
  autoplay: true,
  volume: 1,
  playbackRate: 1,
  loop: false,
  preload: 'auto'
})

const mediaConfig = computed(() => ({
  sources: playlistData.sources,
  poster: playlistData.poster,
  tracks: (playlistData.textTracks) ?? []
}))

const handleMounted = (payload) => {
  state.value = payload.state
  player.value = payload.player
}
</script>
