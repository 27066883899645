<template>
  <div>
    <div
      class="flex items-center px-2 cursor-pointer"
      title="Delete"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Delete Card"
      @close="isOpen = false"
    >
      <div class="bg-white flex w-full p-8">
        Are you sure you want to delete
        <CardIcon
          :brand="card.data.brand"
          class="w-6 mx-2"
        />
        <span>
          *{{ card.data.last4 }}
        </span>?
      </div>
      <div
        class="flex items-center justify-end gap-2 border-t border-gray-200 bg-gray-100 px-8 py-4"
      >
        <div class="cursor-pointer" @click="isOpen = false">
          Cancel
        </div>
        <div class="btn-primary cursor-pointer" @click="destroy">
          Delete
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import { router } from '@inertiajs/vue3';
  import * as $routes from '@/routes.js'
  import DefaultModal from '@/Shared/DefaultModal.vue';
  import CardIcon from '../../Shared/CardIcon.vue';

  const props = defineProps(['card'])
  const isOpen = ref(false)

  const destroy = () => router.delete($routes.payment_method(props.card.id))
</script>
