<template>
  <div class="whitespace-nowrap w-full col-span-1 flex flex-col justify-end">
    <template v-if="$page.props.auth.user.total_minutes < 0">
      <div class="h-5 text-xs font-bold">
        Unlimited Storage
      </div>
    </template>
    <template v-else>
      <div class="w-full h-5 bg-gray-300 relative">
        <div
          class="h-5 text-center"
          :class="$page.props.auth.user.percent_used === 100 ? 'bg-red-800' : $page.props.auth.user.percent_used > 90 ? 'bg-orange-400' : 'bg-primary-600'"
          :style="`width: ${$page.props.auth.user.percent_used}%`"
        >
          <span class="text-xs w-full absolute left-0 whitespace-nowrap text-center font-semibold text-white" style="text-shadow: 1px 1px 3px black">
            {{ $page.props.auth.user.minutes_used }} / {{ $page.props.auth.user.total_minutes < 0 ? '∞' : $page.props.auth.user.total_minutes }} Minutes
          </span>
        </div>
      </div>
    </template>
  </div>
</template>
