<template>
  <div>
    <div
      class="flex items-center px-2 cursor-pointer"
      title="Delete"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Delete Media"
      @close="isOpen = false"
    >
      <div class="bg-white flex w-full p-8">
        Are you sure you want to delete "{{ upload.name }}"?
      </div>
      <div
        class="flex items-center rounded-b justify-end gap-2 border-t border-gray-200 bg-gray-100 px-8 py-4"
      >
        <div class="btn-primary cursor-pointer" @click="destroy">
          Delete
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import DefaultModal from '@/Shared/DefaultModal.vue';
  import { router } from '@inertiajs/vue3';
  import * as $routes from '@/routes.js'
  const props = defineProps(['upload', 'invitation'])
  const isOpen = ref(false)

  const destroy = () => router.delete($routes.quick_destroy(props.upload.hashid, props.invitation.code, { preserveState: true }))
</script>
