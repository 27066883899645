<template>
  <div>
    <div class="mb-4">
      <a v-if="$page.props.auth.user.role==='admin'" href="/admin" class="group flex items-center py-3">
        <WindowIcon
          name="Admin"
          class="mr-2 size-6"
          :class="
            'text-black group-hover:text-primary-500'
          "
        />
        <div
          :class="
            'text-black group-hover:text-primary-500'
          "
        >
          Admin
        </div>
      </a>
    </div>
    <div v-if="$env.VITE_ENABLE_CLIENTS && $page.props.auth.user.business" class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.clients()"
        role="navigation"
        aria-label="Clients"
      >
        <UserGroupIcon
          name="Clients"
          class="mr-2 size-6"
          :class="
            isUrl('clients') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        />
        <div
          :class="
            isUrl('clients') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        >
          Clients
        </div>
      </Link>
    </div>
    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.playlists()"
        role="navigation"
        aria-label="Playlists"
      >
        <QueueListIcon
          name="Playlists"
          class="mr-2 size-6"
          :class="
            isUrl('playlists') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        />
        <div
          :class="
            isUrl('playlists') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        >
          Playlists
        </div>
      </Link>
    </div>
    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.uploads()"
        role="navigation"
        aria-label="Uploads"
      >
        <FilmIcon
          name="Media"
          class="mr-2 size-6"
          :class="
            isUrl('uploads') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        />
        <div
          :class="
            isUrl('uploads') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        >
          Media
        </div>
      </Link>
    </div>
    <div v-if="$env.VITE_ENABLE_ORDERS" class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.orders()"
        role="navigation"
        aria-label="Orders"
      >
        <ShoppingCartIcon
          name="Orders"
          class="mr-2 size-6"
          :class="
            isUrl('orders') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        />
        <div
          :class="
            isUrl('orders') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        >
          Orders
        </div>
      </Link>
    </div>
    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.support()"
        role="navigation"
        aria-label="Orders"
      >
        <LifebuoyIcon
          name="Support"
          class="mr-2 size-6"
          :class="
            isUrl('support') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        />
        <div
          :class="
            isUrl('support') ? 'text-primary-700 group-hover:text-primary-500' : 'text-black group-hover:text-primary-500'
          "
        >
          Need Help?
        </div>
      </Link>
    </div>
  </div>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { FilmIcon, QueueListIcon, WindowIcon, UserGroupIcon, ShoppingCartIcon, LifebuoyIcon } from '@heroicons/vue/24/outline';

const isUrl = (...urls) => {
  let currentUrl = usePage().url.substring(1);

  if (urls[0] === '') {
    return currentUrl === '';
  }

  return urls.filter((url) => currentUrl.startsWith(url)).length;
}
</script>
