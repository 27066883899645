<template>
  <form class="flex flex-col md:flex-row gap-4" @submit.prevent="$emit('submit')">
    <div class="rounded bg-white p-6 w-full flex flex-col gap-4">
      <Aircheck v-model="form.order.projects" />
      <div>
        <div class="btn-secondary inline-block  cursor-pointer" @click="addProject">
          Add Project
        </div>
      </div>
    </div>
    <div class="max-w-fit flex flex-col gap-2">
      <TextareaInput
        v-model="form.order.notes"
        label="Order Notes"
      />
      <div class="text-sm">
        Estimated order cost: <DollarAmount :amount="form.order.total" />
      </div>
      <div>
        <slot />
      </div>
    </div>
  </form>
</template>

<script setup>

import { computed, watch } from 'vue';
import Aircheck from './ProductElements/Aircheck.vue';
import TextareaInput from '../../Shared/TextareaInput.vue';
import DollarAmount from '../../Shared/DollarAmount.vue';
import { v4 as uuid } from 'uuid';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'submit']);

const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});
const addProject = () => {
  form.value.order.projects.push({
    id: uuid(),
    title: '',
    media: 'movie',
    aired: true,
    air_date: '',
    scenes: [ {
      id: uuid(),
      description: ''
    }]
  })
}

watch([props.modelValue], () => {
  let scenes = 0
  form.value.order.projects.forEach((p) => scenes += p.scenes.filter((s)=>!!s.description.length).length)
  form.value.order.total = 28 * scenes
})

</script>
