<template>
  <div>
    <button class="btn-primary" @click="isOpen = true">
      Add to Playlist
    </button>
    <DefaultModal
      :open="isOpen"
      title="Add to Playlist"
      @close="isOpen = false"
    >
      <div class="flex flex-col w-full p-8">
        <Combobox v-if="!create" v-slot="{open}" v-model="selected">
          <label class="form-label">Select Playlist</label>
          <div class="relative">
            <div
              class="relative w-full cursor-default overflow-hidden rounded bg-white text-left border border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300"
            >
              <ComboboxInput
                class="w-full border-none py-2.5 pl-2 pr-10 focus:ring-0"
                :display-value="(pick) => open ? '' : pick?.title"
                :placeholder="'Start Typing to Search'"
                @change="query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 w-full flex items-center pr-2"
              >
                <ChevronUpDownIcon
                  class="size-5 text-gray-400 absolute right-2"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leave-from="opacity-100"
              leave-to="opacity-0"
              @after-leave="query = ''"
            >
              <ComboboxOptions
                class="absolute mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
              >
                <div
                  v-if="filteredPlaylists.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Nothing found.
                </div>

                <ComboboxOption
                  v-for="playlist in filteredPlaylists"
                  :key="playlist.id"
                  v-slot="{ selected, active }"
                  as="template"
                  :value="playlist"
                >
                  <li
                    class="relative cursor-default select-none py-2 pl-10 pr-4"
                    :class="{
                      'bg-primary text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    {{ playlist.title }}
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3"
                      :class="{ 'text-white': active, 'text-primary': !active }"
                    >
                      <CheckIcon class="size-5" aria-hidden="true" />
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>
        <div v-if="form.errors?.playlist?.length" class="form-error">
          Playlist {{ form.errors?.playlist[0] }}
        </div>
        <TextInput v-if="!!create" v-model="createForm.playlist.title" :errors="createForm.errors.title" label="Playlist Title" />
      </div>
      <div
        class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
      >
        <div v-if="!create && !!$page.props.auth.user.storage_plan" class="cursor-pointer text-primary-700" @click="create = true">
          Create Playlist
        </div>
        <div v-if="!!create" class="cursor-pointer text-primary-700" @click="create = false">
          Select Playlist
        </div>
        <div class="btn-primary cursor-pointer" @click="submit">
          Add
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import * as $routes from '@/routes.js'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import TextInput from '@/Shared/TextInput.vue';

const props = defineProps(['upload'])
const playlists = usePage().props.playlists.data
const selected = ref(playlists.find((playlist) => playlist.selected === true))
const query = ref('')
const isOpen = ref(false);
const create = ref(!playlists.length);

const filteredPlaylists = computed(() =>
  query.value === ''
    ? playlists
    : playlists.filter((playlist) =>
      playlist.title
        .toLowerCase().replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    )
)

const form = useForm({
  playlist_upload: {
    upload_id: props.upload.id,
    playlist_id: null
  }
})

const createForm = useForm({
  playlist: {
    title: '',
    upload_id: props.upload.id,
  }
})

watch([selected], () => {
  form.playlist_upload.playlist_id = selected.value?.id
});

const complete = () => {
  form.reset()
  createForm.reset()
  selected.value = null
  isOpen.value = false
}
const submit = () => {
  if (!create.value) {
    form.post($routes.playlist_uploads(),
      {
        onSuccess: () => complete()
      })
  } else {
    createForm.post($routes.playlists(), {
      onSuccess: () => complete()
    })
  }
}
</script>
