<template>
  <span
    :class="{
      'text-green-500' : status === 'active' || status === 'trialing',
      'text-orange-500' : status === 'past_due' || status === 'incomplete' || status === 'unpaid',
      'text-red-600' : status === 'incomplete_expired' || status === 'canceled'
    }"
  >{{ titleCase(status) }}</span>
</template>
<script setup>
import { usePage } from '@inertiajs/vue3';

const status = usePage().props.auth.user.storage_sub?.status
const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())
</script>
