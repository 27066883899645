<template>
  <span>
    {{ humanizeDuration(duration) }}
  </span>
</template>
<script setup>
defineProps({
  duration: {
    type: Number
  }
});

const humanizeDuration = (time) => {
  let hrs = ~~(time / 3600);
  let mins = ~~((time % 3600) / 60);
  let secs = ~~time % 60;

  let ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + String(mins).padStart(2, '0') + ":" + String(secs).padStart(2, '0');
  return ret;
}
</script>
