<template>
  <InvitationForm v-model="form" @submit="submit">
    <div
      class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
    >
      <LoadingButton
        :loading="form.processing"
        class="btn-primary"
        type="submit"
      >
        Invite
      </LoadingButton>
    </div>
  </InvitationForm>
</template>

<script setup>
import LoadingButton from '@/Shared/LoadingButton.vue';
import InvitationForm from './Form.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import * as $routes from '@/routes';

const emit = defineEmits(['success']);

const form = useForm({
  invitation: {
    permissions: {
      media: { view: true, manage: false, upload: true},
      playlists: [ { id: "default", view: true, manage: false, upload: true} ]
    },
    invitable_type: import.meta.env.VITE_ENABLE_CLIENTS && usePage().props.auth.user.business ? 'Company' : 'Team',
    invitable_id: import.meta.env.VITE_ENABLE_CLIENTS && usePage().props.auth.user.business ? usePage().props.auth.user.company.id : usePage().props.auth.user.team.id
  }
})

const submit = () => {
      form.post($routes.invitations(), {
        onSuccess: () => {
          emit('success');
          form.reset('invitation');
        },
      });
    }
</script>
