<template>
  <div class="md:flex md:flex-col">
    <div class="flex h-screen flex-col">
      <div class="md:flex md:flex-shrink-0">
        <div
          class="flex items-center justify-between px-4 py-2 md:w-56 md:flex-shrink-0 md:justify-center bg-brand"
        >
          <Link
            class="flex items-center w-full"
            :href="$routes.root()"
            aria-label="Home"
            role="navigation"
            :title="$env.VITE_APP_NAME"
          >
            <Logo class="h-12 m-auto" />
            <div class="sr-only">
              {{ $env.VITE_APP_NAME }}
            </div>
          </Link>
          <Popover class="absolute right-2 md:hidden">
            <PopoverButton
              class="group focus-visible:outline-none text-white"
              title="Show Menu"
            >
              <Bars3Icon class="size-7" />
            </PopoverButton>
            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0"
            >
              <PopoverPanel
                class="absolute right-0 z-[60] -mt-2 w-screen max-w-fit"
              >
                <div class="mt-2 rounded bg-brand-400 px-8 py-4 shadow-lg">
                  <MainMenu />
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
        <div
          class="md:text-md flex w-full items-center justify-between border-b bg-white p-4 text-sm md:px-12 md:py-0"
        >
          <div class="mr-4 mt-1">
            <div v-if="$page.props.auth.user.impersonating" class="flex gap-1">
              <svg class="size-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M383.9 308.3l23.9-62.6c4-10.5-3.7-21.7-15-21.7h-58.5c11-18.9 17.8-40.6 17.8-64v-.3c39.2-7.8 64-19.1 64-31.7 0-13.3-27.3-25.1-70.1-33-9.2-32.8-27-65.8-40.6-82.8-9.5-11.9-25.9-15.6-39.5-8.8l-27.6 13.8c-9 4.5-19.6 4.5-28.6 0L182.1 3.4c-13.6-6.8-30-3.1-39.5 8.8-13.5 17-31.4 50-40.6 82.8-42.7 7.9-70 19.7-70 33 0 12.6 24.8 23.9 64 31.7v.3c0 23.4 6.8 45.1 17.8 64H56.3c-11.5 0-19.2 11.7-14.7 22.3l25.8 60.2C27.3 329.8 0 372.7 0 422.4v44.8C0 491.9 20.1 512 44.8 512h358.4c24.7 0 44.8-20.1 44.8-44.8v-44.8c0-48.4-25.8-90.4-64.1-114.1zM176 480l-41.6-192 49.6 32 24 40-32 120zm96 0l-32-120 24-40 49.6-32L272 480zm41.7-298.5c-3.9 11.9-7 24.6-16.5 33.4-10.1 9.3-48 22.4-64-25-2.8-8.4-15.4-8.4-18.3 0-17 50.2-56 32.4-64 25-9.5-8.8-12.7-21.5-16.5-33.4-.8-2.5-6.3-5.7-6.3-5.8v-10.8c28.3 3.6 61 5.8 96 5.8s67.7-2.1 96-5.8v10.8c-.1.1-5.6 3.2-6.4 5.8z" /></svg>
              Impersonating -
              <button class="text-primary hover:text-primary-700 cursor-pointer" @click="router.delete($routes.impersonate_engine_revert_impersonate_user())">
                Switch to own account
              </button>
            </div>
            {{ $page.props.auth.user.company?.name }}
          </div>
          <Popover class="relative">
            <PopoverButton
              class="group focus-visible:outline-none"
            >
              <div class="group-hover:text-primary-600 inline-flex items-center">
                <span>{{ $page.props.auth.user.first_name }}
                  <span class="hidden md:inline">{{ $page.props.auth.user.last_name }}</span>
                </span>
                <div
                  v-if="$page.props.auth.user.to_accept_count > 0"
                  class="ml-2 rounded-full bg-primary-600 size-2 text-xs text-center text-white"
                />
                <ExclamationTriangleIcon
                  v-if="$page.props.auth.user.storage_sub?.status === 'past_due' || $page.props.auth.user.storage_sub?.status === 'incomplete'"
                  class="text-orange-500 size-4 ml-2"
                />
                <ChevronDownIcon
                  class="ml-2 size-4"
                  aria-hidden="true"
                />
              </div>
            </PopoverButton>
            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0"
            >
              <PopoverPanel
                class="absolute right-0 z-[60] mt-3 w-screen max-w-64"
              >
                <div class="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black/5">
                  <Link
                    class="block w-full px-4 py-2 text-left"
                    role="navigation"
                    :href="$routes.account()"
                  >
                    Manage Account
                  </Link>
                  <template v-if="$env.VITE_ENABLE_CLIENTS">
                    <Link
                      v-if="$page.props.auth.user.team"
                      class="block w-full px-4 py-2 text-left"
                      role="navigation"
                      :href="$routes.representation()"
                    >
                      Representation
                      <div
                        v-if="$page.props.auth.user.to_accept_count > 0"
                        class="ml-2 rounded-full bg-primary-600 size-4 text-xs text-center text-white inline-block"
                      >
                        {{ $page.props.auth.user.to_accept_count }}
                      </div>
                    </Link>
                  </template>
                  <template v-else>
                    <Link
                      v-if="$page.props.auth.user.team"
                      class="block w-full px-4 py-2 text-left"
                      role="navigation"
                      :href="$routes.collaboration_index()"
                    >
                      Collaboration
                      <div
                        v-if="$page.props.auth.user.to_accept_count > 0"
                        class="ml-2 rounded-full bg-primary-600 size-4 text-xs text-center text-white inline-block"
                      >
                        {{ $page.props.auth.user.to_accept_count }}
                      </div>
                    </Link>
                  </template>
                  <Link
                    v-if="$page.props.auth.user.company && ($page.props.auth.user.company.user_id === $page.props.auth.user.id)"
                    class="block w-full px-4 py-2 text-left"
                    role="navigation"
                    :href="$routes.company()"
                  >
                    Manage Company
                    <div
                      v-if="$page.props.auth.user.to_accept_count > 0"
                      class="ml-2 rounded-full bg-primary-600 size-4 text-xs text-center text-white inline-block"
                    >
                      {{ $page.props.auth.user.to_accept_count }}
                    </div>
                  </Link>
                  <template v-if="$page.props.auth.user.storage_sub">
                    <StorageUsed class="px-4 py-2" />
                    <Link
                      class="block w-full px-4 py-2"
                      role="navigation"
                      :href="$routes.subscriptions()"
                    >
                      <div class="flex justify-between">
                        <span class="text-xs">
                          {{ $page.props.auth.user.storage_sub ? $page.props.auth.user.storage_plan.name : "Sponsored" }}
                        </span>
                        <span class="text-xs text-green-600 capitalize">
                          <SubscriptionStatus />
                        </span>
                      </div>
                    </Link>
                  </template>
                  <template v-else-if="!$page.props.auth.user.company">
                    <StorageUsed class="px-4 py-2" />
                    <Link
                      class="block w-full px-4 py-2"
                      role="navigation"
                      :href="$routes.subscriptions()"
                    >
                      <div class="flex justify-between">
                        <span class="text-xs">
                          Free Account
                        </span>
                        <span class="text-xs text-green-600">
                          Upgrade
                        </span>
                      </div>
                    </Link>
                  </template>
                  <Link
                    class="block w-full px-4 py-2 text-left"
                    :href="$routes.destroy_user_session()"
                    method="delete"
                    as="button"
                  >
                    Logout
                  </Link>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
      </div>
      <div class="md:flex md:flex-grow md:overflow-hidden">
        <div class="hidden w-56 bg-brand-400 p-8 md:block h-full">
          <MainMenu
            class="flex-shrink-0 overflow-y-auto "
          />
          <AdBlock v-if="width > 767" />
        </div>

        <div
          class="px-4 py-8 md:flex-1 md:overflow-y-auto md:p-12 h-full"
          scroll-region
        >
          <FlashMessages />
          <slot />
          <AdBlock v-if="width < 768" class="mt-4" />
        </div>

      </div>
    </div>
    <footer class="py-5 text-xs text-center text-gray-500 bg-gray-900 mt-auto flex flex-col md:flex-row items-center gap-1 justify-center">
      <div>
        © {{ new Date().getFullYear() }} {{ $env.VITE_APP_NAME }}. All Rights Reserved.
      </div>
      <div>
        <a class="text-gray-300" target="blank" :href="`${$env.VITE_BROCHUREWARE_URL}/terms-and-conditions/`">Terms of Service</a> | <a class="text-gray-300" target="blank" :href="`${$env.VITE_BROCHUREWARE_URL}/privacy-policy/`">Privacy Policy</a>
      </div>
    </footer>
  </div>
</template>
<script setup>
import * as $routes from '../../routes';
import { Link, router } from '@inertiajs/vue3';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { Bars3Icon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import SubscriptionStatus from '../../Pages/Subscriptions/Components/Status.vue';
import StorageUsed from '../../Shared/StorageUsed.vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/16/solid';
import Logo from '../../Shared/Logo.vue';
import MainMenu from '../../Shared/MainMenu.vue';
import FlashMessages from '../../Shared/FlashMessages.vue';
import AdBlock from '../../Shared/AdComponent.vue';

import { useWindowSize } from '@vueuse/core'

const { width } = useWindowSize()
</script>
