<template>
  <Layout>
    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <Head title="Representation" />
      <div class="w-full max-w-2xl">
        <Logo class="mx-auto block w-full max-w-xs h-24" />
        <div class="mt-8 overflow-hidden rounded-lg bg-white shadow-xl">
          <div class="px-10 py-12">
            <FlashMessages />
            <h1 class="text-3xl font-bold mb-4">
              Teamwork Makes the Dream Work
            </h1>
            <ul v-if="!$page.props.auth.user.business" class="list-disc ml-4 mb-2 gap-2 flex flex-col">
              <li>Add unlimited agents and managers at no additional cost</li>
              <li>Linked reps can view, download and create custom playlists with your media</li>
            </ul>
            <p v-else class="mb-4">
              Invite your clients here
              <br><br>
              <span class="text-sm">
                Tip: email us a .csv file and we will invite your clients for you.
              </span>
            </p>
            <table v-if="!!invitations" class="w-full whitespace-nowrap">
              <tbody>
                <tr
                  v-for="invite in invitations"
                  :key="invite.id"
                  class="focus-within:bg-gray-100 hover:bg-gray-100"
                >
                  <td class="border-t">
                    <div
                      class="px-6 py-4"
                    >
                      <div>{{ invite.first_name }} {{ invite.last_name }}</div>
                      <div class="text-xs">
                        <span v-if="!!invite.role" class="capitalize">{{ invite.role }} - </span>{{ invite.email }}
                      </div>
                    </div>
                  </td>
                  <td class="w-px border-t">
                    <div class="flex flex-row px-2">
                      <DestroyInvitation :invitation="invite">
                        <TrashIcon
                          class="size-6 text-red-700 hover:text-red-600"
                        />
                      </DestroyInvitation>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center btn-primary cursor-pointer mb-4" @click="modalNew = true">
              Invite <span class="hidden md:inline">{{ !$page.props.auth.user.business ? 'Representation' : 'Client' }}</span>
            </div>
            <DefaultModal
              :open="modalNew"
              :title="`Invite ${!$page.props.auth.user.business ? 'Representation' : 'Client'}`"
              @close="modalNew = false"
            >
              <NewInvitation @success="modalNew = false" />
            </DefaultModal>
            <div class="flex flex-col gap-4">
              <h2 class="text-2xl font-semibold">
                Next Steps...
              </h2>
              <Link :href="$routes.subscriptions()" class="btn-outline text-center">
                Upgrade
              </Link>
              <Link :href="$routes.new_order()" class="btn-outline text-center">
                Order Footage
              </Link>
              <a href="https://reeltoday.com/appointments" class="btn-outline text-center" target="_blank">
                {{ $page.props.auth.user.business ? 'Book a Demonstration' : 'Create or Update Reel' }}
              </a>
              <Link :href="$routes.uploads()" class="btn-outline text-center">
                Explore
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue';
import FlashMessages from '@/Shared/FlashMessages.vue';
import Logo from '@/Shared/Logo.vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import NewInvitation from '@/Pages/Collaboration/New.vue';
import { ref } from 'vue';
import DateString from '../../Shared/DateString.vue';
import { ArrowPathIcon, EnvelopeIcon, LockClosedIcon, TrashIcon, UserIcon } from '@heroicons/vue/24/outline';
import DestroyInvitation from '../Invitations/Destroy.vue';
import DestroyRelationship from '../TeamCollaborators/Destroy.vue';
import InvitePermissions from '../Invitations/Permissions.vue';
import Permissions from '../Collaboration/Permissions.vue';
import { invitation } from '../../routes';

defineProps({
  invitations: {
    type: Array,
    required: true,
  }
})

const modalNew = ref(false);
</script>
