<template>
  <div>
    <div
      class="flex items-center cursor-pointer max-w-fit"
      title="Cancel"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Cancel Subscription"
      @close="isOpen = false"
    >
      <div class="bg-white flex flex-col md:flex-row w-full p-8 rounded-b gap-3">
        <div class="flex flex-col gap-4">
          <p class="text-lg font-semibold">
            Are you sure you want to cancel your membership?
          </p>
          <p>Your membership benefits will remain active until your expiration date.</p>
          <div>
            <Link class="btn-primary cursor-pointer inline-block" :href="$routes.subscriptions_confirm_cancel()">
              Confirm Cancellation
            </Link>
          </div>
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import DefaultModal from '@/Shared/DefaultModal.vue';
  import { Link } from '@inertiajs/vue3';
  import * as $routes from '@/routes';
  import DateString from '../../Shared/DateString.vue';

  const isOpen = ref(false)

  const close = () => isOpen.value = false
</script>
