<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" class="relative z-50" :class="{'opacity-0': !cameraOpen}" :style="`width:${width}px;height:${height-60}px;`" @close="close">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="`max-w-screen w-full transform text-left align-middle transition-all absolute inset-0`"
            >
              <div class="relative h-full">
                <slot />
                <button
                  type="button"
                  class="rounded p-1 hover:bg-gray-300 focus-visible:outline-none absolute top-0 right-0 z-[999999]"
                  title="Close"
                  @click="close"
                >
                  <XMarkIcon
                    class="size-5 text-white"
                  />
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { useWindowSize } from '@vueuse/core'

const { width, height } = useWindowSize()

defineProps({
  open: Boolean,
  title: String,
  cameraOpen: Boolean,
})
const emit = defineEmits(['close']);
const close = () => emit('close')
</script>
