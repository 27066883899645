<template>
  <Layout>
    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <Head title="Login" />
      <div class="w-full max-w-md">
        <Logo class="mx-auto block w-full max-w-xs h-24" />
        <form
          class="mt-8 overflow-hidden rounded-lg bg-white shadow-xl"
          @submit.prevent="form.post($routes.user_confirmation())"
        >
          <div class="px-10 py-12">
            <FlashMessages />

            <h1 class="text-center text-3xl font-bold">
              Resend confirmation instructions
            </h1>
            <div class="mx-auto mt-6 w-24 border-b-2" />
            <TextInput
              v-model="form.user.email"
              :errors="form.errors.email || $page.props.errors?.email"
              class="mt-10"
              label="Email"
              type="email"
              autofocus
              autocapitalize="off"
              autocomplete="email"
            />
          </div>
          <div class="flex border-t border-gray-200 bg-gray-100 px-10 py-4 items-center">
            <div class="text-sm">
              Don’t have an account yet?
              <Link :href="$routes.new_registration()">
                Sign up
              </Link>
            </div>
            <LoadingButton
              :loading="form.processing"
              class="btn-primary ml-auto"
              type="submit"
            >
              Resend
            </LoadingButton>
          </div>
        </form>
        <Link :href="$routes.new_user_session()" class="mt-4 block text-center text-white">
          Login
        </Link>
        <Link :href="$routes.new_user_password()" class="mt-4 block text-center text-white">
          Forgot password?
        </Link>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue';
import FlashMessages from '@/Shared/FlashMessages.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import Logo from '@/Shared/Logo.vue';
import TextInput from '@/Shared/TextInput.vue';

const form = useForm({
  user: {
    email: '',
  },
})
</script>
