<template>
  <div class="w-full">
    <input ref="deviceCapture" type="file" accept="video/*" capture="user" class="hidden" @change="(e) => recordedFromDevice(e)">
    <div class="flex gap-2 mx-auto max-w-fit">
      <div v-if="!((/iPad|iPhone|iPod/.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && deviceCapture?.capture !== undefined" class="btn-secondary inline-block" @click="deviceCapture.click()">
        Open Camera
      </div>
      <template v-else>
        <div class="btn-secondary inline-block cursor-pointer" @click="openCamera">
          Open Camera
        </div>
      </template>
      <template v-if="$env.VITE_ENABLE_SCREEN_RECORD">
        <div class="btn-secondary inline-block cursor-pointer" @click="startRecordingScreen">
          Record Screen
        </div>
      </template>
    </div>
    <div v-if="!!recorderError" class="text-xs text-red-700 mt-2">
      {{ recorderError }}
    </div>

    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
    <RecordingModal
      :camera-open
      :open="isOpen"
      title="Record"
      @close="closeModal()"
    >
      <div :class="cameraOpen ? 'absolute inset-0 w-full z-[999999]' : 'relative'" class="h-full">
        <video
          v-show="cameraOpen"
          ref="video"
          :autoplay="!!cameraOpen && (!video?.src || !!recording )"
          playsinline
          :controls="!!video?.src && !recording"
          class="bg-black w-full h-full"
          :class="{ 'pb-11' : !!video?.src && !recording }"
        />
        <div v-if="!!video?.src && cameraOpen && !recording" class="absolute bottom-0 left-0 w-full flex flex-row">
          <div class="text-white hover:text-primary-200 cursor-pointer whitespace-nowrap border-t border-r border-white/20 w-1/2 px-6 py-3 text-sm text-center" @click="retryRecording">
            Retry
          </div>
          <div class="text-white hover:text-primary-200 cursor-pointer whitespace-nowrap border-t border-white/20 w-1/2 px-6 py-3 text-sm text-center" @click="submitFile">
            Submit
          </div>
        </div>
        <div v-if="!video?.src && !recording && cameraOpen" class="absolute bottom-6 w-full">
          <div class="bg-gray-200 hover:bg-gray-100 rounded-full p-2 cursor-pointer m-auto w-fit" @click="startRecordingCamera">
            <div class="size-9 rounded-full bg-red-700 m-1" />
          </div>
        </div>
        <div v-if="recording" class="absolute bottom-6 w-full">
          <div class="bg-gray-200 hover:bg-gray-100 rounded-full p-3 cursor-pointer m-auto w-fit" @click="stopRecording">
            <div class="size-8 bg-black rounded m-1" />
          </div>
        </div>
        <div v-if="recording" class="absolute top-6 w-full">
          <div class="bg-red-400 text-white rounded-full p-2 m-auto w-fit">
            <DurationString :duration="counter" class="mx-auto inline-block "/>
          </div>
        </div>
      </div>
    </RecordingModal>
  </div>
</template>

<script setup>
import RecordRTC from 'recordrtc'
import fixWebmDuration from "fix-webm-duration";
import { onUnmounted, ref } from 'vue';
import DurationString from '../../Shared/DurationString.vue';
import RecordingModal from '../../Shared/RecordingModal.vue';
import { useInterval } from '@vueuse/core'

const video = ref()
const recorder = ref()
const startTime = ref()
const cameraOpen = ref(false)
const recording = ref(false)
const source = ref('camera')
const deviceCapture = ref()
const recorderError = ref('')
const isOpen = ref(false)
const tempFile = ref()

defineProps({
  modelValue: File,
  errors: {
    type: Array,
    default: () => [],
  },
})

const { counter, reset} = useInterval(1000, { controls: true })

const emit = defineEmits(['update:modelValue', 'recording'])

const startRecordingScreen = () => {
  isOpen.value = true
  source.value='screen'
  captureScreen(function(screen) {
    video.value.srcObject = screen;

    recorder.value = RecordRTC(screen, {
      type: 'video'
    });

    recording.value=true
    emit('recording', true)
    reset()
    startTime.value = Date.now()
    recorder.value.startRecording();
    cameraOpen.value = true;
    // release screen on stopRecording
    recorder.value.screen = screen;
  });
}

const openCamera = () => {
  isOpen.value = true
  source.value='camera'
  captureCamera(function(camera) {
    cameraOpen.value=true;
    video.value.muted = true;
    video.value.volume = 0;
    video.value.srcObject = camera;

    if (MediaRecorder.isTypeSupported('video/mp4')) {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/mp4',
        timeSlice: 1000
      });
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/webm;codecs=h264',
        timeSlice: 1000
      });
    } else {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/webm',
        timeSlice: 1000
      });
    }
    // release camera on stopRecording
    recorder.value.camera = camera;
  });
};

const startRecordingCamera = () => {
  recording.value = true
  emit('recording', true)
  reset()
  startTime.value = Date.now()
  recorder.value.startRecording();
}

const stopRecording = () => {
  recorder.value?.stopRecording(stopRecordingCallback);
}

const invokeGetDisplayMedia = (success, error) => {
  let displaymediastreamconstraints = {
    video: {
      displaySurface: 'monitor', // monitor, window, application, browser
      logicalSurface: true,
      cursor: 'always' // never, always, motion
    }
  };

  // above constraints are NOT supported YET
  // that's why overriding them
  displaymediastreamconstraints = {
    video: true
  };

  if(navigator.mediaDevices.getDisplayMedia) {
    navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
  }
  else {
    navigator.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
  }
}

const captureScreen = (callback) => {
  invokeGetDisplayMedia(function(screen) {
    addStreamStopListener(screen, function() {
      stopRecording();
    });
    callback(screen);
  }, function(error) {
    console.error(error);
    alert('Unable to capture your screen. Please check console logs.\n' + error);
  });
}

const captureCamera = (callback) => {
  navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function(camera) {
    callback(camera);
  }).catch(function(error) {
    cameraOpen.value = false
    stopRecording();
    if (deviceCapture.value?.capture !== undefined) {
      deviceCapture.value.click()
    } else {
      recorderError.value = 'Unable to find a suitable camera!';
      console.error(error);
    }
  });
}

const stopRecordingCallback = () => {
  video.value.src = video.value.srcObject = null;
  let duration = Date.now() - startTime.value

  if (MediaRecorder.isTypeSupported('video/webm;codecs=h264') || MediaRecorder.isTypeSupported('video/mp4')) {
    let blob = recorder.value.getBlob();
    video.value.src = URL.createObjectURL(blob);
    tempFile.value = new File([blob], `Recorded-${new Date().toJSON()}.mp4`, {
      type: 'video/mp4'
    });
  } else {
    fixWebmDuration(
      recorder.value.getBlob(),
      duration,
      (seekableBlob) => {
        video.value.src = URL.createObjectURL(seekableBlob);
        tempFile.value = new File([seekableBlob], `Recorded-${new Date().toJSON()}.webm`, {
          type: 'video/webm'
        });
      }
    );
  }

  recording.value=false
  emit('recording', false)
  if (source.value === 'screen') {
    recorder.value.screen.stop();
  } else if (source.value === 'camera') {
    video.value.muted = false;
    video.value.volume = 1;
    recorder.value.camera.stop();
  }
  recorder.value.destroy();
  recorder.value = null;
}

const addStreamStopListener = (stream, callback) => {
  stream.addEventListener('ended', function() {
    callback();
    callback = function() {};
  }, false);
  stream.addEventListener('inactive', function() {
    callback();
    callback = function() {};
  }, false);
  stream.getTracks().forEach(function(track) {
    track.addEventListener('ended', function() {
      callback();
      callback = function() {};
    }, false);
    track.addEventListener('inactive', function() {
      callback();
      callback = function() {};
    }, false);
  });
}

defineExpose({ stopRecording })

const recordedFromDevice = (e) => {
  emit('update:modelValue', e.target.files[0])
}

const retryRecording = () => {
  source.value='camera'
  captureCamera(function(camera) {
    cameraOpen.value=true;
    video.value.muted = true;
    video.value.volume = 0;
    video.value.srcObject = camera;

    if (MediaRecorder.isTypeSupported('video/mp4')) {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/mp4',
        timeSlice: 1000
      });
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/webm;codecs=h264',
        timeSlice: 1000
      });
    } else {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/webm',
        timeSlice: 1000
      });
    }

    recording.value = true
    emit('recording', true)
    reset()
    startTime.value = Date.now()
    recorder.value.startRecording();
    // release camera on stopRecording
    recorder.value.camera = camera;
  });
}

const navigator = window.navigator

const submitFile = () => {
  cameraOpen.value = false
  isOpen.value = false
  emit('update:modelValue', tempFile.value);
}

const closeModal = () => {
  if(recording.value === true) {
    stopRecording()
    isOpen.value = false
    if(confirm('Would you like to save your recording in progress?')) {
      emit('update:modelValue', tempFile.value);
    }
  } else {
    isOpen.value = false
  }
}

onUnmounted(() => {
  recording.value=false
  emit('recording', false)
  if (source.value === 'screen') {
    recorder.value?.screen?.stop();
  } else if (source.value === 'camera') {
    recorder.value?.camera?.stop();
  }
  recorder.value?.destroy();
  recorder.value = null;
});
</script>
