<template>
  <tr
    v-if="showPermissions()"
    class="focus-within:bg-gray-100 hover:bg-gray-100 text-sm "
  >
    <td class="border-t">
      <div
        class="p-2 text-left"
      >
        <div v-if="type==='invitation'">
          {{ object.first_name }} {{ object.last_name }}
        </div>
        <div v-else>
          {{ object.collaborator.name }}
        </div>
      </div>
    </td>
    <td class="border-t">
      <span v-if="!!$page.props.media.find((m)=>m.author.hashid === object.hashid)" class="text-green-700 text-xs flex">Contributed <CheckIcon class="size-4 ml-2" /></span>
    </td>
    <td class="border-t">
      <div v-if="form[type].permissions.playlists.find((p)=>p.id===playlist.hashid)" class="flex gap-4 items-center text-xs">
        <div>
          <label class="cursor-pointer">
            View
            <Checkbox
              v-model="form[type].permissions.playlists.find((p)=>p.id===playlist.hashid).view"
              :checked="!!form[type].permissions.playlists.find((p)=>p.id===playlist.hashid).view"
              class="cursor-pointer"
            />
          </label>
        </div>
        <div>
          <label class="cursor-pointer">
            Manage
            <Checkbox
              v-model="form[type].permissions.playlists.find((p)=>p.id===playlist.hashid).manage"
              :checked="!!form[type].permissions.playlists.find((p)=>p.id===playlist.hashid).manage"
              class="cursor-pointer"
            />
          </label>
        </div>
        <div>
          <label class="cursor-pointer">
            Upload
            <Checkbox
              v-model="form[type].permissions.playlists.find((p)=>p.id===playlist.hashid).upload"
              :checked="!!form[type].permissions.playlists.find((p)=>p.id===playlist.hashid).upload"
              class="cursor-pointer"
            />
          </label>
        </div>
      </div>
      <div v-else class="flex gap-4 items-center text-xs ">
        <div>
          <label class="cursor-pointer">
            View
            <Checkbox disabled :checked="!!form[type].permissions.playlists.find((p)=>p.id==='default').view" />
          </label>
        </div>
        <div>
          <label class="cursor-pointer">
            Manage
            <Checkbox disabled :checked="!!form[type].permissions.playlists.find((p)=>p.id==='default').manage" />
          </label>
        </div>
        <div>
          <label class="cursor-pointer">
            Upload
            <Checkbox disabled :checked="!!form[type].permissions.playlists.find((p)=>p.id==='default').upload" />
          </label>
        </div>
      </div>
    </td>
    <td class="border-t w-20">
      <div class="text-xs">
        <button v-if="form.isDirty" :disabled="form.processing" class="flex items-center whitespace-nowrap rounded bg-primary px-3 py-1.5 font-semibold text-white" @click="submit">
          <div v-if="form.processing" class="btn-spinner mr-2" />
          Save
        </button>
        <span v-if="form.recentlySuccessful" class="text-green-700">Saved!</span>
      </div>
    </td>
  </tr>
</template>

<script setup>
import Checkbox from '../../../Shared/Checkbox.vue';
import { useForm } from '@inertiajs/vue3';
import { computed } from 'vue';
import * as $routes from '../../../routes';
import { CheckIcon } from '@heroicons/vue/24/outline';

const props = defineProps(['permissions', 'playlist', 'type', 'object'])

const buildPermissions = (pp) => {
  if (!pp.playlists.find((p) => p.id === props.playlist.hashid)) {
    pp.playlists.push(
      { id: props.playlist.hashid,
        view: props.permissions.playlists.find((p) => p.id ==='default').view,
        manage: props.permissions.playlists.find((p) => p.id ==='default').manage,
        upload: props.permissions.playlists.find((p) => p.id ==='default').upload
      })
  }
  return pp
}

const form = useForm({
  [props.type]: {
    permissions: buildPermissions(props.permissions)
  }
})

const isOverridden = computed(() => {
  return !(form[props.type].permissions.playlists.find((p) => p.id === props.playlist.hashid)?.view
    === form[props.type].permissions.playlists.find((p) => p.id ==='default')?.view &&
    form[props.type].permissions.playlists.find((p) => p.id === props.playlist.hashid)?.manage
    === form[props.type].permissions.playlists.find((p) => p.id ==='default')?.manage &&
    form[props.type].permissions.playlists.find((p) => p.id === props.playlist.hashid)?.upload
    === form[props.type].permissions.playlists.find((p) => p.id ==='default')?.upload)
})

const route = () => {
  if (props.type === 'invitation') {
    return $routes.invitation(props.object.code)
  } else {
    return $routes.collaboration(props.object.id)
  }
}

const submit = () => {
  if(!isOverridden.value) {
    form[props.type].permissions.playlists = form[props.type].permissions.playlists.filter((p) => p.id !== props.playlist.hashid)
  }
  form.put(route(), {
    onSuccess: () => {
      form[props.type].permissions = buildPermissions(form[props.type].permissions)
    },
  });
}

const showPermissions = () => {
  return (!!form[props.type].permissions.playlists.find((p)=>p.id===props.playlist.hashid) && (
    !!form[props.type].permissions.playlists.find((p)=>p.id===props.playlist.hashid)?.view ||
    !!form[props.type].permissions.playlists.find((p)=>p.id===props.playlist.hashid)?.manage ||
    !!form[props.type].permissions.playlists.find((p)=>p.id===props.playlist.hashid)?.upload
  )) || form.isDirty
}
</script>
