<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" class="relative z-50" @close="close">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-70" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="`${maxWidth} w-full transform rounded bg-white
                            text-left align-middle shadow-xl transition-all`"
            >
              <DialogTitle
                as="div"
                class="text-xl font-bold bg-gray-100 px-8 py-4 flex flex-row gap-2
                                justify-between overflow-hidden rounded-t border-b border-gray-200 "
              >
                {{ title }}
                <button
                  type="button"
                  class="rounded p-1 hover:bg-gray-300 focus-visible:outline-none"
                  title="Close"
                  @click="close"
                >
                  <XMarkIcon
                    class="size-5"
                  />
                </button>
              </DialogTitle>
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { XMarkIcon } from "@heroicons/vue/24/solid";

defineProps({
    open: Boolean,
    title: String,
    maxWidth: {
        type: String,
        default: 'max-w-lg'
    }
})
const emit = defineEmits(['close']);
const close = () => emit('close')
</script>
