<template>
  <Layout>
    <Head title="Orders" />
    <h1 class="mb-8 text-3xl font-bold">
      Orders
    </h1>
    <div class="mb-6 flex items-center justify-between gap-4">
      <SearchFilter
        v-model="search"
        class="w-full max-w-md"
        @reset="reset"
      />

      <Link :href="$routes.new_order()" class="btn-primary">
        Create <span class="hidden md:inline">Order</span>
      </Link>
    </div>
    <div class="mb-6 flex items-center justify-between gap-4">
      <div class="rounded bg-white shadow w-full">
        <table class="w-full whitespace-nowrap">
          <thead v-if="orders.data.length">
            <tr class="text-left font-bold hidden lg:table-row">
              <th class="px-6 pb-4 pt-6">
                Order
              </th>
              <th class="px-6 pb-4 pt-6">
                Date
              </th>
              <th class="px-6 pb-4 pt-6">
                Status
              </th>
              <th class="px-6 pb-4 pt-6">
                Total
              </th>
              <th class="px-6 pb-4 pt-6 w-px" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orders.data" :key="index">
              <td class="border-t">
                <Link
                  class="flex flex-col px-6 py-4 focus:text-primary-600 hover:text-primary-600 text-primary"
                  :href="$routes.order(order.hashid)"
                >
                  {{ order.hashid }}
                </Link>
              </td>
              <td class="border-t">
                <div class="px-6 py-4">
                  <DateString :date="order.created_at" month-format="long" />
                </div>
              </td>
              <td class="border-t">
                <div class="px-6 py-4">
                  {{ order.status }}
                </div>
              </td>
              <td class="border-t">
                <div class="px-6 py-4">
                  <DollarAmount :amount="order.charged_amount_cents / 100" />
                </div>
              </td>
              <td class="border-t w-px">
                <div class="flex px-2">
                  <Link
                    :href="$routes.order(order.hashid)"
                    class="flex items-center px-2 text-primary hover:text-primary-700 ml-auto"
                    title="View"
                  >
                    <EyeIcon class="size-6" />
                  </Link>
                  <a
                    v-if="order.status === 'invoiced'"
                    :href="$routes.pay_order(order.hashid)"
                    class="flex items-center px-2 text-primary hover:text-primary-700"
                    title="Pay"
                  >
                    <CreditCardIcon class="size-6" />
                  </a>
                </div>
              </td>
            </tr>
            <tr v-if="orders.data.length === 0">
              <td class="border-t px-6 py-4" colspan="5">
                No orders found.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination v-if="orders.meta?.last > 1" :meta="orders.meta" />
  </Layout>
</template>

<script setup>
import { Head, Link, router } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import Pagination from '../../Shared/Pagination.vue';
import DateString from '../../Shared/DateString.vue';
import DollarAmount from '../../Shared/DollarAmount.vue';
import { EyeIcon, CreditCardIcon } from '@heroicons/vue/24/outline';
import SearchFilter from '../../Shared/SearchFilter.vue';
import { ref, watch } from 'vue';
import { debounce } from 'vue-debounce';

const props = defineProps({
  orders: {
    type: Object,
    required: true,
  },
  filters: {
    type: Object,
    required: true,
  },
})

const search = ref(props.filters.search ?? '');
const submitDebounce = debounce(() => submitSearch(), 600);

watch([search], () => {
  submitDebounce();
});

const submitSearch = () => router.reload({
  data: {
    q: {
      title_cont: search.value,
    }
  },
  preserveState:false,
  preserveScroll:true
});

const reset = () => search.value = ''
</script>
