<template>
  <button class="btn-primary flex items-center" @click="isOpen = true">
    <UserGroupIcon class="size-5 mr-2" /> Collaboration
  </button>
  <DefaultModal
    :open="isOpen"
    title="Collaboration Settings"
    @close="isOpen = false"
  >
    <div class="bg-white rounded-b flex flex-col text-center w-full p-8">
      <table class="bg-white shadow rounded">
        <QuickPermissions
          v-for="invite in $page.props.invitations"
          :key="invite.id" :permissions="invite.permissions" :playlist type="invitation" :object="invite"
        />
        <QuickPermissions
          v-for="collab in $page.props.collaborations"
          :key="collab.id" :permissions="collab.permissions" :playlist type="team_collaborator" :object="collab"
        />
      </table>
    </div>
    <InvitationForm v-model="form" :playlist @submit="submit">
      <div
        class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
      >
        <LoadingButton
          :loading="form.processing"
          class="btn-primary"
          type="submit"
        >
          Invite
        </LoadingButton>
      </div>
    </InvitationForm>
  </DefaultModal>
</template>
<script setup>
import { UserGroupIcon } from '@heroicons/vue/24/outline';
import DefaultModal from '../../../Shared/DefaultModal.vue';
import { ref } from 'vue';
import InvitationForm from '../../Collaboration/Form.vue';
import { useForm } from '@inertiajs/vue3';
import LoadingButton from '../../../Shared/LoadingButton.vue';
import QuickPermissions from './QuickPermissions.vue';
import * as $routes from '../../../routes';

const props = defineProps(['playlist'])

const isOpen = ref(false)

const form = useForm({
  invitation: {
    permissions: {
      media: { view: false, manage: false, upload: false},
      playlists: [
        { id: "default", view: false, manage: false, upload: false},
        { id: props.playlist.hashid, view: false, manage: false, upload: true}
      ]
    }
  }
})

const submit = () => {
  form.post($routes.invitations(), {
    onSuccess: () => {
      form.reset('invitation');
    },
  });
}
</script>
