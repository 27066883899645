<template>
  <div>
    <button
      class="flex items-center px-2 text-primary hover:text-primary-700"
      title="QR Code"
      @click="isOpen = true"
    >
      <QrCodeIcon class="size-6" />
    </button>
    <DefaultModal
      :open="isOpen"
      title="QR Code"
      @close="isOpen = false"
    >
      <div class="bg-white flex flex-col text-center w-full p-8">
        <p class="mb-2">
          Playlist: {{ playlist.title }}
        </p>
        <img :src="$routes.playlist_qrcode(playlist.hashid)" class="w-96 m-auto" :alt="`${playlist.title} QR Code`">
      </div>
      <div
        class="flex items-center justify-end gap-2 rounded-b border-t border-gray-200 bg-gray-100 px-8 py-4"
      >
        <a class="btn-primary" :href="$routes.playlist_qrcode(playlist.hashid)" target="_blank">
          Download
        </a>
        <a class="btn-primary" :href="$routes.flyer(playlist.hashid)" target="_blank">
          Full screen
        </a>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import * as $routes from '@/routes.js'
import DefaultModal from '@/Shared/DefaultModal.vue';
import { QrCodeIcon } from '@heroicons/vue/24/outline';

defineProps(['playlist', 'showOwner'])
const isOpen = ref(false)
</script>
