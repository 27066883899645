<template>
  <div>
    <slot />
  </div>
</template>

<script setup>
import { pageview } from 'vue-gtag'
import { onMounted } from 'vue';
import { usePage } from '@inertiajs/vue3';

onMounted(() => { pageview({ page_path: usePage().url }) })
</script>
