<template>
  <div>
    <div
      class="flex items-center cursor-pointer"
      title="Change Payment"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Change Payment"
      @close="isOpen = false"
    >
      <div class="bg-white flex flex-col w-full p-8 gap-3">
        <div v-if="newCard">
          <Payment
            ref="paymentComponent"
            :client-secret="$page.props.setupIntent"
            @success="(setupIntent) => intentConfirmed(setupIntent)"
            @failure="(error) => handleError(error)"
          />
          <div v-if="errors" class="flex justify-between items-center">
            <div class="text-xs font-medium text-red-700 mr-12">
              {{ errors.message }}
            </div>
          </div>
          <div v-if="$page.props.errors" class="flex justify-between items-center">
            <div v-for="(error, index) in $page.props.errors?.pay" :key="index" class="text-xs font-medium text-red-700 mr-12">
              {{ error }}
            </div>
          </div>
        </div>
        <div v-else>
          <template v-for="card in nonSubCards" :key="card.id">
            <InputRadio v-model="form.pm_id" class="flex items-center px-2 py-2 gap-2 hover:bg-gray-100" name="payment_method" :value="card.id">
              <CardIcon
                :brand="card.data.brand"
                class="w-6 mr-2"
              />
              <span class="text-sm">
                {{ card.data.last4 }}
              </span>
              <span
                class="text-sm"
              >
                Expires {{ card.data.exp_month }}/{{ card.data.exp_year }}
              </span>
            </InputRadio>
          </template>
        </div>
      </div>
      <div
        class="flex items-center rounded-b gap-2 border-t border-gray-200 bg-gray-100 px-8 py-4"
        :class="nonSubCards.length ? 'justify-between' : 'justify-end'"
      >
        <template v-if="nonSubCards.length">
          <div v-if="newCard" class="cursor-pointer text-primary-700" @click="newCard = false">
            Use Saved Card
          </div>
          <div v-else class="cursor-pointer text-primary-700" @click="newCard = true">
            Add New Card
          </div>
        </template>
        <LoadingButton class="btn-primary cursor-pointer" :loading="form.processing || cardProcessing" @click="update">
          Update
        </LoadingButton>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import Payment from '../../Shared/SetupIntent.vue';
import LoadingButton from '../../Shared/LoadingButton.vue';
import * as $routes from '@/routes';
import CardIcon from '../../Shared/CardIcon.vue';
import InputRadio from '../../Shared/InputRadio.vue';

const isOpen = ref(false)
const cards = usePage().props.cards
const nonSubCards = cards.filter((c) => c.processor_id !== usePage().props.auth.user.storage_sub.payment_method_id)
const newCard = ref(!nonSubCards.length)
const paymentComponent = ref()
const cardProcessing = ref(false)
const errors = ref(null)

const update = () => {
  if (newCard.value) {
    cardProcessing.value=true
    paymentComponent.value.createPaymentMethod()
  } else {
    form.post($routes.subscriptions_payment_confirm(), {
      onSuccess: () => {
        form.reset();
        isOpen.value = false;
        cardProcessing.value = false;
        newCard.value = false;
      }
    })
  }
}

const form = useForm({
  stripe_pm_id: null,
  pm_id: null
})

const intentConfirmed = (setupIntent) => {
  form.stripe_pm_id = setupIntent.payment_method
  form.post($routes.subscriptions_payment_confirm(), {
    onSuccess: () => {
      form.reset();
      isOpen.value = false;
      cardProcessing.value = false;
      newCard.value = false;
    },
    onError: () => {
      cardProcessing.value = false
    }
  })
}

const handleError = (error) => {
  cardProcessing.value = false
  errors.value = error
}
</script>
