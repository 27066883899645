<template>
  <div id="checkout-container" />
</template>

<script setup>
import { loadStripe } from '@stripe/stripe-js'
import { onMounted } from 'vue';

const props = defineProps(['clientSecret']);
const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

onMounted(() => {
  stripe.then(async (stripe) => {
    const checkout = await stripe?.initEmbeddedCheckout({
      clientSecret: props.clientSecret,
    });

    checkout?.mount("#checkout-container");
  });
});
</script>
