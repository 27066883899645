<template>
  <Layout>
    <Head title="Media" />
    <h1 class="mb-8 text-3xl font-bold">
      {{ playlist.title }}
    </h1>
    <h2 v-if="playlist.uploads.length" class="text-xl mb-4">
      You've done it!  Now it's time to add more media, share, or explore!
    </h2>
    <div class="bg-white rounded-b max-w-prose">
      <div class="gap-6 flex w-full p-8 flex-col">
        <DropZone
          ref="dropZone"
          :form-data="formData"
          :route="$routes.uploads()"
          @processing="dzProcessing = true"
          @success="dzProcessing = false"
          @ready="(count) => readyCount = count"
        />
      </div>
      <div
        v-show="readyCount > 0"
        class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
      >
        <LoadingButton
          :loading="dzProcessing"
          class="btn-primary ml-auto"
          @click="submit"
        >
          Upload {{ readyCount }} Media
        </LoadingButton>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '../../Shared/LoadingButton.vue';
import * as $routes from '../../routes';
import { ref } from 'vue';
import DropZone from '../../Shared/DropZone.vue';
const props = defineProps(['playlist'])
const dropZone = ref()
const dzProcessing = ref(false)
const readyCount = ref(0)

const formData = {
  upload: { },
  playlist_id: props.playlist.hashid,
  signed_blob_id: null
}

const submit = () => {
  dropZone.value.submit();
}
</script>
