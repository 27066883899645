<template>
  <Layout>
    <Head title="Password" />
    <div class="mb-4 flex max-w-3xl justify-start">
      <h1 class="text-3xl font-bold">
        Set Password
      </h1>
    </div>
    <p class="mb-8 font-light text-gray-500 sm:text-xl">
      Please designate a secure password for future logins
    </p>
    <div class="max-w-3xl overflow-hidden rounded bg-white shadow w-full md:w-auto">
      <ChangePassword v-model="formPw" @submit="submitPw">
        <div
          class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
        >
          <LoadingButton
            :loading="formPw.processing"
            class="btn-primary ml-auto"
            type="submit"
          >
            Next&nbsp;<ArrowRightIcon class="size-4 inline" />&nbsp;Create First Tribute
          </LoadingButton>
        </div>
      </ChangePassword>
    </div>
  </Layout>
</template>

<script setup>
import { Head, router, useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import ChangePassword from '../Auth/ChangePassword.vue';
import * as $routes from '@/routes';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';


const props = defineProps({
  user: {
    type: Object,
    required: true,
  }
})

const formPw = useForm({
  user: {
    password:null,
    password_confirmation: null
  },
})

const submitPw = () => {
  formPw.put($routes.user_credential(props.user.id), {
    onSuccess: () => router.visit($routes.root()),
  });
}

</script>
