<template>
  <Layout>
    <Head :title="`${form.user.first_name} ${form.user.last_name}`" />
    <div class="mb-8 flex max-w-3xl justify-start">
      <h1 class="text-3xl font-bold">
        My Account
      </h1>
    </div>

    <ProfileMenu v-if="$page.props.auth.user && !$page.props.auth.user.company || $page.props.auth.user.company?.user?.id === $page.props.auth.user.id" />

    <div class="flex flex-wrap gap-4 justify-between">
      <div class="max-w-3xl overflow-hidden rounded bg-white shadow w-full md:w-auto">
        <UserForm v-model="form" @submit="submit">
          <div
            class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
          >
            <LoadingButton
              :loading="form.processing"
              class="btn-primary ml-auto"
              type="submit"
            >
              Update Profile
            </LoadingButton>
          </div>
        </UserForm>
      </div>

      <div class="max-w-3xl overflow-hidden rounded bg-white shadow w-full md:w-auto">
        <ChangePassword v-model="formPw" @submit="submitPw">
          <div
            class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
          >
            <LoadingButton
              :loading="formPw.processing"
              class="btn-primary ml-auto"
              type="submit"
            >
              Update Password
            </LoadingButton>
          </div>
        </ChangePassword>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import UserForm from './Form.vue';
import ChangePassword from './ChangePassword.vue';
import * as $routes from '@/routes';
import ProfileMenu from './Components/ProfileMenu.vue';

const props = defineProps({
  user: {
    type: Object,
    required: true,
  }
})

const form = useForm({
  user: {
    ...props.user,
  },
})
const formPw = useForm({
  user: {
    password:null,
    password_confirmation: null
  },
})

const submit = () => {
  form.put($routes.account(), {
    onSuccess: () => form.reset('first_name', 'last_name', 'email'),
  });
}

const submitPw = () => {
  formPw.put($routes.user_credential(props.user.id), {
    onSuccess: () => formPw.reset(),
  });
}

</script>
