<template>
  <Layout>
    <Head title="Storage Allocation Overview" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-primary-500 hover:text-primary-800"
        :href="$routes.company()"
      >
        {{ $page.props.auth.user.company.name }}
      </Link>
      <span class="font-medium text-primary-400"> / </span>
      Storage
    </h1>
    <CompanyMenu />
    <div v-if="$page.props.auth.user.storage_sub" class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="px-6 pb-4 pt-6">
              Client
            </th>
            <th class="px-6 pb-4 pt-6">
              Subscription Minutes
            </th>
            <th class="px-6 pb-4 pt-6">
              Allocated Minutes
            </th>
            <th class="px-6 pb-4 pt-6">
              Total Usage
            </th>
            <th class="px-6 pb-4 pt-6">
              Allocation Usage
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="client in clients" :key="client.id"
          >
            <td class="border-t px-6 py-4">
              {{ client.user.name }}
            </td>
            <td class="border-t px-6 py-4">
              {{ client.user.sub_minutes || 0 }}
            </td>
            <td class="border-t px-6 py-4">
              <div class="flex items-center">
                {{ formattedMinutes(client.sponsorship) }}
                <StorageAllocation :user="client.user" :allocated="client.sponsorship">
                  <PencilSquareIcon name="Storage" class="size-6" />
                </StorageAllocation>
              </div>
            </td>
            <td class="border-t px-6 py-4">
              {{ client.user.minutes_used || 0 }}
            </td>
            <td class="border-t px-6 py-4">
              {{ !!client.sponsorship && client.user.minutes_used > client.user.sub_minutes ?
                client.user.minutes_used - client.user.sub_minutes :
                0 }}
            </td>
          </tr>
          <tr v-if="clients.length === 0">
            <td class="border-t px-6 py-4" colspan="4">
              No clients.
            </td>
          </tr>
        </tbody>
        <tfoot v-if="clients.length > 0" class="bg-gray-100">
          <tr>
            <td class="border-t px-6 py-4" colspan="4">
              <div class="w-full flex">
                <strong class="ml-auto">Total Allocation Usage</strong>
              </div>
            </td>
            <td class="border-t px-6 py-4">
              <div class="flex flex-col">
                <span>{{ totalUsed }}</span>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <template v-else>
      <div class="px-4 mx-auto max-w-screen-md">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold">
          Storage for everyone
        </h2>
        <p class="mb-8 font-light text-gray-700 sm:text-xl">
          Rep Pro allows you to upload your own media and allocate storage to your clients and sub-accounts.
        </p>
        <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 mb-4">
          <a class="btn-primary" href="/subscriptions">Subscribe to add storage </a>
        </div>
      </div>
    </template>
  </Layout>
</template>
<script setup>
import Layout from '@/Layouts/Main.vue';
import { Head, Link } from '@inertiajs/vue3';
import CompanyMenu from '../Company/Components/CompanyMenu.vue';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import * as $routes from '../../routes';
import StorageAllocation from '../Collaboration/StorageAllocation.vue';

defineProps({
  clients: Array,
  totalUsed: Number
})

const formattedMinutes = (allocated) => {
  return allocated?.storage_limit < 0 ? '∞' : allocated?.storage_limit || 0
}
</script>
