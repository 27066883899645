<template>
  <form @submit.prevent="submit">
    <div class="gap-2 flex flex-col w-full p-8">
      <div class="text-lg">
        Media Library
      </div>
      <div class="flex gap-4">
        <div>
          <label class="form-label">
            View
            <Checkbox v-model="form.invitation.permissions.media.view" :checked="!!form.invitation.permissions.media.view" />
          </label>
        </div>
        <div>
          <label class="form-label">
            Manage
            <Checkbox v-model="form.invitation.permissions.media.manage" :checked="!!form.invitation.permissions.media.manage" />
          </label>
        </div>
        <div>
          <label class="form-label">
            Upload
            <Checkbox v-model="form.invitation.permissions.media.upload" :checked="!!form.invitation.permissions.media.upload" />
          </label>
        </div>
      </div>

      <div class="text-lg">
        Playlists
      </div>
      <div class="font-bold">
        Default
      </div>
      <div class="flex gap-4">
        <div>
          <label class="form-label">
            View
            <Checkbox v-model="form.invitation.permissions.playlists.find((p)=>p.id==='default').view" :checked="!!form.invitation.permissions.playlists.find((p)=>p.id==='default').view" />
          </label>
        </div>
        <div>
          <label class="form-label">
            Manage
            <Checkbox v-model="form.invitation.permissions.playlists.find((p)=>p.id==='default').manage" :checked="!!form.invitation.permissions.playlists.find((p)=>p.id==='default').manage" />
          </label>
        </div>
        <div>
          <label class="form-label">
            Upload
            <Checkbox v-model="form.invitation.permissions.playlists.find((p)=>p.id==='default').upload" :checked="!!form.invitation.permissions.playlists.find((p)=>p.id==='default').upload" />
          </label>
        </div>
      </div>
      <template v-for="playlist in $page.props.playlists" :key="playlist.hashid">
        <div class="font-bold">
          {{ playlist.name }}
        </div>
        <div v-if="!!playlistPermissions(playlist.hashid)" class="flex gap-4  items-center">
          <div>
            <label class="form-label">
              View
              <Checkbox v-model="playlistPermissions(playlist.hashid).view" :checked="!!playlistPermissions(playlist.hashid).view" />
            </label>
          </div>
          <div>
            <label class="form-label">
              Manage
              <Checkbox v-model="playlistPermissions(playlist.hashid).manage" :checked="!!playlistPermissions(playlist.hashid).manage" />
            </label>
          </div>
          <div>
            <label class="form-label">
              Upload
              <Checkbox v-model="playlistPermissions(playlist.hashid).upload" :checked="!!playlistPermissions(playlist.hashid).upload" />
            </label>
          </div>
          <button class="flex gap-2 text-xs bg-gray-200 rounded p-1 items-center" @click="removeOverride(playlist.hashid)">
            <XCircleIcon class="size-5 text-red-700" /> Default
          </button>
        </div>
        <div v-else class="flex gap-4 items-center ">
          <div>
            <label class="form-label text-gray-400">
              View
              <Checkbox disabled :checked="!!playlistPermissions('default').view" />
            </label>
          </div>
          <div>
            <label class="form-label text-gray-400">
              Manage
              <Checkbox disabled :checked="!!playlistPermissions('default').manage" />
            </label>
          </div>
          <div>
            <label class="form-label text-gray-400">
              Upload
              <Checkbox disabled :checked="!!playlistPermissions('default').upload" />
            </label>
          </div>
          <button class="flex gap-2 text-xs bg-gray-200 rounded p-1 items-center" @click="addOverride(playlist.hashid)">
            <PlusCircleIcon class="size-5" /> Override
          </button>
        </div>
      </template>
    </div>
    <div
      class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
    >
      <LoadingButton
        :loading="form.processing"
        class="btn-primary"
        type="submit"
      >
        Save
      </LoadingButton>
    </div>
  </form>
</template>
<script setup>
import { useForm } from '@inertiajs/vue3';
import Checkbox from '@/Shared/Checkbox.vue';
import { PlusCircleIcon, XCircleIcon} from '@heroicons/vue/24/outline';
import LoadingButton from '@/Shared/LoadingButton.vue';
import * as $routes from '@/routes';

const props = defineProps(['invitation'])
const form = useForm( { invitation: { permissions: props.invitation.permissions }})
const emit = defineEmits(['success']);

const playlistPermissions = (hashid) => {
  return form.invitation.permissions.playlists.find((p)=>p.id===hashid)
}

const removeOverride = (hashid) => {
  form.invitation.permissions.playlists = form.invitation.permissions.playlists.filter((p) => p.id !== hashid)
}

const addOverride = (hashid) => {
  form.invitation.permissions.playlists.push({ id: hashid, view: playlistPermissions('default').view, manage: playlistPermissions('default').manage, upload: playlistPermissions('default').upload })
}

const submit = () => {
  form.put($routes.invitation(props.invitation.code), {
    onSuccess: () => {
      emit('success');
    },
  });
}
</script>
