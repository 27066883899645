<template>
  <Head title="Error" />
  <Minimal v-if="!$page.props.auth.user?.id">
    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <div class="w-full max-w-lg">
        <Link
          class="flex items-center w-full"
          :href="$routes.root()"
          aria-label="Home"
          role="navigation"
          :title="$env.VITE_APP_NAME"
        >
          <Logo class="mx-auto block w-full max-w-xs h-24" />
        </Link>
        <div class="mt-8 overflow-hidden rounded-lg bg-white shadow-xl px-10 py-12">
          <h1 class="text-3xl font-bold">
            {{ title() }}
          </h1>
          <p class="mt-3 text-lg leading-tight">
            {{ description() }}
          </p>
        </div>
        <Link :href="$routes.new_user_session()" class="mt-4 block text-center text-white">
          Login
        </Link>
        <Link :href="$routes.new_registration()" class="mt-4 block text-center text-white">
          Sign up
        </Link>
        <Link :href="$routes.new_user_password()" class="mt-4 block text-center text-white">
          Forgot password?
        </Link>
        <Link :href="$routes.new_user_confirmation()" class="mt-4 block text-center text-white">
          Resend confirmation email
        </Link>
      </div>
    </div>
  </Minimal>
  <Main v-else>
    <h1 class="text-3xl font-bold">
      {{ title() }}
    </h1>
    <p class="mt-3 text-lg leading-tight">
      {{ description() }}
    </p>
  </Main>
</template>

<script setup>
import { Head, Link } from '@inertiajs/vue3';
import * as Sentry from "@sentry/vue";
import { onMounted } from 'vue';
import Main from '../Layouts/Main.vue'
import Minimal from '../Layouts/Minimal.vue'
import Logo from '../Shared/Logo.vue';
import * as $routes from '../routes';

const props = defineProps({
  status: {
    type: Number,
    required: true,
  },
  sentryErrorEventId: {
    type: String,
    required: false
  }
})

const title = () =>
{
  return {
    403: 'Forbidden',
    404: 'Page Not Found',
    500: 'Something went wrong',
    503: 'Service Unavailable',
  }[props.status]
}

const description = () => {
  return {
    403: 'Sorry, you are forbidden from accessing this page.',
    404: 'Sorry, the page you are looking for could not be found.',
    500: 'Our engineers have been notified.',
    503: 'Sorry, we are doing some maintenance. Please check back soon.',
  }[props.status]
}

</script>
