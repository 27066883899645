<template>
  <Layout>
    <Head title="Collaboration" />
    <h1 class="mb-8 text-3xl font-bold">
      Collaboration
    </h1>
    <ProfileMenu />
    <template v-if="$page.props.auth.user.storage_sub && ($page.props.auth.user.storage_plan.collaborators > 0 || $page.props.auth.user.storage_plan.collaborators === -1 )">
      <div class="mb-6 flex items-center justify-between">
        <div class="text-sm">
          {{ collaborators.length + invitations.length }}/{{ $page.props.auth.user.storage_plan.collaborators }} Collaborators
        </div>

        <button class="btn-primary" @click="modalNew = true">
          Invite <span class="hidden md:inline">Collaborator</span>
        </button>

        <DefaultModal
          :open="modalNew"
          title="Invite Collaborator"
          @close="modalNew = false"
        >
          <NewInvitation @success="modalNew = false" />
        </DefaultModal>
      </div>
      <div class="overflow-x-auto rounded bg-white shadow mb-6">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left font-bold">
              <th class="px-6 pb-4 pt-6" colspan="3">
                Collaborators
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="invite in invitations"
              :key="invite.id"
              class="focus-within:bg-gray-100 hover:bg-gray-100"
            >
              <td class="border-t">
                <div
                  class="px-6 py-4"
                >
                  <div>{{ invite.first_name }} {{ invite.last_name }}</div>
                  <div class="text-xs">
                    {{ invite.email }} <span class="italic text-tertiary">
                      Invitation
                      <template v-if="invite.created_at !== invite.updated_at">re-</template>sent <DateString :date="invite.updated_at" /></span>
                  </div>
                </div>
              </td>
              <td class="w-px border-t">
                <div class="flex flex-row px-2">
                  <button class="relative mx-2" title="Resend Invitation" @click="resend(invite.code)">
                    <EnvelopeIcon class="size-6 text-primary" />
                    <ArrowPathIcon class="size-4 absolute bottom-0 right-0 bg-green-100 rounded-full p-0.5" />
                  </button>
                  <InvitePermissions :invitation="invite">
                    <div class="relative mx-2">
                      <UserIcon class="size-6 text-primary" />
                      <LockClosedIcon class="text-black size-4 absolute bottom-0 right-0 bg-green-100 rounded-full p-0.5" />
                    </div>
                  </InvitePermissions>
                  <DestroyInvitation :invitation="invite">
                    <TrashIcon
                      class="size-6 text-red-700 hover:text-red-600"
                    />
                  </DestroyInvitation>
                </div>
              </td>
            </tr>
            <tr
              v-for="collaborator in collaborators"
              :key="collaborator.id"
              class="focus-within:bg-gray-100 hover:bg-gray-100"
            >
              <td class="border-t">
                <div
                  class="flex flex-col px-6 py-4 focus:text-primary justify-content-start"
                >
                  <div>{{ collaborator.user.name }}</div>
                  <div class="text-xs">
                    {{ collaborator.user.email }}
                  </div>
                </div>
              </td>
              <td class="w-px border-t">
                <div class="flex justify-end px-2">
                  <Permissions :collaborator>
                    <div class="relative ml-auto mx-2">
                      <UserIcon class="size-6 text-primary" />
                      <LockClosedIcon class="text-black size-4 absolute bottom-0 right-0 bg-green-100 rounded-full p-0.5" />
                    </div>
                  </Permissions>
                  <DestroyRelationship :team-collaborator="collaborator">
                    <TrashIcon
                      class="size-6 text-red-700 hover:text-red-600 ml-auto"
                    />
                  </DestroyRelationship>
                </div>
              </td>
            </tr>
            <tr v-if="collaborators.length === 0 && invitations.length === 0">
              <td class="border-t px-6 py-4" colspan="4">
                No collaborators found.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else>
      <div class="px-4 mx-auto max-w-screen-md">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold">
          Working together to create memories
        </h2>
        <p class="mb-8 font-light text-gray-700 sm:text-xl">
          Our Plus & Premium plans allow you connect with friends, family and colleagues, and grant them permissions to help manage, invite contributors, and prepare your video tribute.
        </p>
        <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 mb-4">
          <Link :href="$routes.subscriptions()" class="btn-primary">
            {{ !$page.props.auth.user.storage_sub ? 'Subscribe' : 'Upgrade' }} to add collaborators
          </Link>
        </div>
      </div>
    </template>
    <div v-if="toAccept.length > 0 || collaborations.length > 0" class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="px-6 pb-4 pt-6" colspan="4">
              Collaborating With
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="request in toAccept"
            :key="request.id"
            class="focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <td class="border-t">
              <div
                class="px-6 py-4"
              >
                {{ request.user.first_name }} {{ request.user.last_name }}
                <span class="text-xs">has invited you to contribute!</span>
              </div>
            </td>
            <td class="w-px border-t" colspan="3">
              <div class="flex flex-row items-center justify-end px-2">
                <Link
                  :href="`/invitations/${request.code}/accept`"
                  class="text-xs p-1.5 mr-2 bg-primary-600 rounded text-white"
                >
                  Accept
                </Link>
                <DestroyInvitation :invitation="request">
                  <TrashIcon
                    class="size-6 text-red-700 hover:text-red-600"
                  />
                </DestroyInvitation>
              </div>
            </td>
          </tr>
          <tr
            v-for="collaboration in collaborations"
            :key="collaboration.id"
            class="focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <td class="border-t">
              <div
                class="flex flex-col px-6 py-4 focus:text-primary"
              >
                <div>{{ collaboration.user.name }}</div>
                <div class="text-xs">{{ collaboration.user.email }}</div>
              </div>
            </td>

            <td class="w-px border-t">
              <div class="flex px-2">
                <Link
                  v-if="collaboration.media_view"
                  class="flex items-center px-2 text-primary hover:text-primary-700"
                  :href="$routes.uploads({filter: collaboration?.user?.hashid})"
                  tabindex="-1"
                  aria-label="View Media"
                  title="View Media"
                >
                  <FilmIcon
                    name="Media"
                    class="size-6"
                  />
                </Link>
                <Link
                  v-if="collaboration.playlists_any"
                  class="flex items-center px-2 text-primary hover:text-primary-700"
                  :href="$routes.playlists({filter: collaboration?.user?.hashid})"
                  tabindex="-1"
                  aria-label="View Playlists"
                  title="View Playlists"
                >
                  <QueueListIcon
                    name="Playlists"
                    class="size-6"
                  />
                </Link>
                <DestroyRelationship :team-collaborator="collaboration">
                  <TrashIcon
                    class="size-6 text-red-700 hover:text-red-600"
                  />
                </DestroyRelationship>
              </div>
            </td>
          </tr>
          <tr v-if="collaborations.length === 0 && toAccept.length === 0">
            <td class="border-t px-6 py-4" colspan="4">
              Not collaborating with anyone.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/Layouts/Main.vue"
import { Head, Link, router } from '@inertiajs/vue3';
import { ref } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import NewInvitation from '@/Pages/Collaboration/New.vue';
import {
  TrashIcon,
  EnvelopeIcon,
  ArrowPathIcon,
  UserIcon,
  LockClosedIcon,
  FilmIcon,
  QueueListIcon,
} from '@heroicons/vue/24/outline';
import DateString from '../../Shared/DateString.vue';
import DestroyInvitation from '../Invitations/Destroy.vue';
import DestroyRelationship from '../TeamCollaborators/Destroy.vue';
import Permissions from './Permissions.vue';
import InvitePermissions from '../Invitations/Permissions.vue';
import ProfileMenu from '../Auth/Components/ProfileMenu.vue';
import * as $routes from '@/routes';

defineProps({
  invitations: {
    type: Array,
    required: true,
  },
  toAccept: {
    type: Array,
    required: true,
  },
  collaborators: {
    type: Array,
    required: true,
  },
  collaborations: {
    type: Array,
    required: true,
  }
})

const modalNew = ref(false);

const resend = (code) => {
  router.visit($routes.invitation_resend_notifications(code))
}
</script>
